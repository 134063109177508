import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../media_assets';

const fakeResponse = {
  data: {
    id: '86fe3dc6-905b-11e8-856e-232bde3a808d',
    type: 'media_assets',
    attributes: {
      id: '86fe3dc6-905b-11e8-856e-232bde3a808d',
      organization_id: '3340615c-8168-11e8-bc0f-2b6b8e2bd540',
      content_id: '5e9d1d7a-905b-11e8-856e-0b5401061c45',
      media_id: '89789270-9053-11e8-938e-539381a25e32',
      active: true,
      identifier: 'image',
      language: 'en',
      name: 'image media asset',
      created_at: '2018-07-25T22:39:01.602Z',
      updated_at: '2018-07-25T22:39:01.602Z',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
    },
  },
};

describe('MediaAssets Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media_assets.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        content_id: '5e9d1d7a-905b-11e8-856e-0b5401061c45',
        media_id: '89789270-9053-11e8-938e-539381a25e32',
        active: true,
        identifier: 'image',
        language: 'en',
        name: 'image media asset',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media_assets.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media_assets.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media_assets.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media_assets.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
