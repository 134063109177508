import update from 'immutability-helper';
import _ from 'lodash';
import ApiClient from '~/services/client';
import { notificationError } from '~/utils/models';
import { getPageInfo } from '../utils/table';
import { redirectToIndex } from '../utils/router';
import initialState from './initialStates/resource';

const resource = 'users';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery } = payload;
      const response = yield call(ApiClient.users.search, pageNumber, sortField, sortOrder, searchQuery);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put, select }) {
      const { params } = payload;
      const response = yield call(ApiClient.users.create, params);
      yield put({
        type: 'createResource',
        payload: response,
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.users.read, id);
      yield put({
        type: 'currentResource',
        payload: response.data,
      });
    },
    *update({ payload }, { call, put, select }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.users.update, id, params);
      yield put({
        type: 'updateResource',
        payload: response.data,
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *delete({ payload }, { call, put, select }) {
      const { id } = payload;
      yield call(ApiClient.users.delete, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *password({ payload }, { call, put }) {
      const { id, password, password_confirmation } = payload;
      try {
        yield call(ApiClient.users.password, id, { password, password_confirmation });
        yield put({ type: 'notifications/success' });
      } catch (e) { yield put(notificationError(e)); }
    },
  },
  reducers: {
    readResource(state, action) {
      const { data, links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      return {
        ...state,
        list: data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const { id, attributes } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
        resource: attributes,
      };
    },
    currentResource(state, action) {
      const { id, attributes } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
        resource: attributes,
      };
    },
    deleteResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1]] }),
      };
    },
  },
};
