import ApiClient from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '~/utils/table';
import initialState from './initialStates/resource';

const resource = 'observations';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, pageSize, sortField, sortOrder, filters, searchQuery, extraQueries } = payload;
      const response = yield call(ApiClient.observations.search, {
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        filters,
        searchQuery,
        extraQueries,
      });
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { params } = payload;
      const response = yield call(ApiClient.observations.create, params);
      yield put({
        type: 'createResource',
        payload: response,
      });
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.observations.read, id);
      yield put({
        type: 'currentResource',
        payload: response.data,
      });
    },
    *update({ payload }, { call, put }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.observations.update, id, params);
      if (response) {
        yield put({
          type: 'updateResource',
          payload: response.data,
        });
      }
    },
    *delete({ payload }, { call, put }) {
      const { id } = payload;
      yield call(ApiClient.observations.delete, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put({
        type: 'fetch',
        payload: { id },
      });
    },
  },
  reducers: {
    readResource(state, action) {
      const { links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const { data } = deserialise(action.payload);
      return {
        ...state,
        list: data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state) {
      return {
        ...state,
      };
    },
    currentResource(state, action) {
      const { data } = deserialise(action.payload);
      return {
        ...state,
        resource: data,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
  },
};
