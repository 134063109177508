import update from 'immutability-helper';
import _ from 'lodash';
import ApiClient from '~/services/client';
import { notification } from 'antd';
import { notificationError } from '~/utils/models';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { redirectToIndex } from '../utils/router';
import initialState from './initialStates/resource';

const resource = 'questionnaire_responses';
const parentResource = 'care_plan_activities';

export default {
  namespace: resource,
  state: {
    ...initialState,
    normalized: {},
  },
  effects: {
    *fetch({ payload }, { call, put }) {
      const { parentId, pageNumber, sortField, sortOrder, searchQuery, extraQueries } = payload;
      const response = yield call(ApiClient.questionnaire_responses.search, { parentId, pageNumber, sortField, sortOrder, searchQuery, extraQueries });
      yield put({
        type: 'readResource',
        payload: {
          ...response,
          extraQueries,
        },
      });
    },
    *create({ payload }, { call, put, select }) {
      const { parentId, params } = payload;
      const response = yield call(ApiClient.questionnaire_responses.create, parentId, params);
      yield put({
        type: 'createResource',
        payload: response,
      });

      const carePlanId = yield select(state => state.care_plans.resource.id);

      yield put({
        type: 'care_plans/read',
        payload: {
          id: carePlanId,
        },
      });
    },
    *read({ payload }, { call, put }) {
      const { parentId, id } = payload;
      const response = yield call(ApiClient.questionnaire_responses.read, parentId, id);
      yield put({
        type: 'currentResource',
        payload: response.data,
      });
    },
    *update({ payload }, { call, put, select }) {
      const { parentId, id, params } = payload;
      const response = yield call(ApiClient.questionnaire_responses.update, parentId, id, params);
      yield put({
        type: 'updateResource',
        payload: response.data,
      });
      yield call(redirectToIndex, { put, select }, resource, parentResource, parentId);
    },
    *delete({ payload }, { call, put, select }) {
      const { parentId, id } = payload;
      yield call(ApiClient.questionnaire_responses.delete, parentId, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield call(redirectToIndex, { put, select }, resource, parentResource, parentId);
    },
    *rescore({ payload }, { call, put }) {
      const {
        id,
        carePlanId,
        extraQueries: {
          carePlanActivityId: parentId,
        },
      } = payload;
      try {
        yield call(ApiClient.questionnaire_responses.rescore, id);
        notification.success({
          message: 'Scored successfully',
        });
        const response = yield call(ApiClient.questionnaire_responses.read, parentId, id);
        yield put({
          type: 'rescoreResource',
          payload: deserialise(response),
        });
        yield put({
          type: 'care_plans/read',
          payload: {
            id: carePlanId,
          },
        });
      } catch (e) {
        yield put(notificationError(e));
      }
    },
  },
  reducers: {
    readResource(state, action) {
      const { data, extraQueries } = action.payload;
      const normalized = {
        ...state.normalized,
        ..._.reduce(data, (result, value) => ({
          ...result,
          [value.id]: {
            ...state.normalized[value.id],
            ...value,
            ...extraQueries,
          },
        }), {}),
      };
      return {
        ...state,
        list: data,
        normalized,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const { id, attributes } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
        resource: attributes,
      };
    },
    currentResource(state, action) {
      const { id, attributes } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
        resource: attributes,
      };
    },
    rescoreResource(state, action) {
      const { normalized } = state;
      const { data } = action.payload;
      return {
        ...state,
        normalized: update(
          normalized,
          {
            [data.id]: {
              attributes: {
                scores: { $set: data.scores },
              },
            },
          },
        ),
      };
    },
    deleteResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1]] }),
      };
    },
  },
};
