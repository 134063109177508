import { call, put, select } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../patients';

const fakeResponse = {
  data: {
    id: 'ddc07e42-101a-11e8-9084-9bd741eba3a4',
    type: 'patients',
    attributes: {
      id: 'ddc07e42-101a-11e8-9084-9bd741eba3a4',
      organization_id: 'ddaa7494-101a-11e8-9084-3f4e50f5cca4',
      given: 'Clemens',
      family: 'Reichert',
      name: 'Clemens Reichert',
      email: 'lenore_lebsack@muller.co',
      phone: '(481) 481-1524',
      address: '850 Ziemann Island',
      source: null,
      source_id: null,
      identifier: null,
      active: true,
      gender: 'female',
      birthdate: '1985-05-30',
      language: 'en',
      properties: null,
      do_not_call: false,
      refreshed_at: null,
      deleted_at: null,
      checksum: '',
      created_at: '2018-02-12T17:33:41.036Z',
      updated_at: '2018-02-12T17:33:41.036Z',
      created_by: null,
      updated_by: null,
      address_city: null,
      address_state: null,
      address_postalcode: null,
      address_country: null,
    },
  },
};

describe('Patient Model', () => {
  const stateOnPatients = {
    routing: {
      location: {
        pathname: '/patients',
      },
    },
  };

  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
      filters: { active: true },
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.patients.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: {
        ...fakeResponse,
        filters: fakeSearchAction.payload.filters
      },
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        given: 'Clemens',
        family: 'Reichert',
        email: 'lenore_lebsack@muller.co',
        phone: '(481) 481-1524',
        address: '850 Ziemann Island',
        gender: 'female',
        birthdate: '1985-05-30',
        active: true,
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put, select })
    .withState(stateOnPatients)
    .provide([
      [matchers.call.fn(ApiClient.patients.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.patients.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };

  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put, select })
    .withState(stateOnPatients)
    .provide([
      [matchers.call.fn(ApiClient.patients.update), fakeResponse],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete on /patients route', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put, select })
    .withState(stateOnPatients)
    .provide([
      [matchers.call.fn(ApiClient.patients.delete), ''],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .put({
      type: 'fetch',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());

  const stateOutsidePatients = {
    routing: {
      location: {
        pathname: '/patients/id',
      },
    },
  };
  test('delete outside /patients route', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put, select })
    .withState(stateOutsidePatients)
    .provide([
      [matchers.call.fn(ApiClient.patients.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
