import ApiClient from '~/services/client';
import update from 'immutability-helper';
import _ from 'lodash';
import { getPageInfo } from '../utils/table';
import initialState from './initialStates/resource';

const resource = 'care_plan_activities';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { parentId, pageNumber, sortField, sortOrder, searchQuery } = payload;
      const response = yield call(ApiClient.care_plan_activities.search, parentId, pageNumber, sortField, sortOrder, searchQuery);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { parentId, params } = payload;
      const response = yield call(ApiClient.care_plan_activities.create, parentId, params);
      yield put({
        type: 'createResource',
        payload: response,
      });
      yield put({
        type: 'care_plans/read',
        payload: {
          id: parentId,
        },
      });
    },
    *read({ payload }, { call, put }) {
      const { parentId, id } = payload;
      const response = yield call(ApiClient.care_plan_activities.read, parentId, id);
      yield put({
        type: 'currentResource',
        payload: response.data,
      });
    },
    *update({ payload }, { call, put }) {
      const { parentId, id, params } = payload;
      const response = yield call(ApiClient.care_plan_activities.update, parentId, id, params);
      if (response) {
        yield put({
          type: 'updateResource',
          payload: response.data,
        });
        yield put({
          type: 'care_plans/read',
          payload: {
            id: parentId,
          },
        });
      }
    },
    *delete({ payload }, { call, put }) {
      const { parentId, id } = payload;
      yield call(ApiClient.care_plan_activities.delete, parentId, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put({
        type: 'care_plans/read',
        payload: {
          id: parentId,
        },
      });
    },
  },
  reducers: {
    readResource(state, action) {
      const { data, links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      return {
        ...state,
        list: data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const { id, attributes } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
        resource: attributes,
      };
    },
    currentResource(state, action) {
      const { id, attributes } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
        resource: attributes,
      };
    },
    deleteResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1]] }),
      };
    },
  },
};
