import { createElement } from 'react';
import dynamic from 'dva/dynamic';
import pathToRegexp from 'path-to-regexp';
import { getMenuData } from './menu';

let routerDataCache;

const modelNotExisted = (app, model) =>
  // eslint-disable-next-line
  !app._models.some(({ namespace }) => {
    return namespace === model.substring(model.lastIndexOf('/') + 1);
  });

// wrapper of dynamic
const dynamicWrapper = (app, models, component) => {
  // () => require('module')
  // transformed by babel-plugin-dynamic-import-node-sync
  if (component.toString().indexOf('.then(') < 0) {
    models.forEach((model) => {
      if (modelNotExisted(app, model)) {
        // eslint-disable-next-line
        app.model(require(`../models/${model}`).default);
      }
    });
    return (props) => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return createElement(component().default, {
        ...props,
        routerData: routerDataCache,
      });
    };
  }
  // () => import('module')
  return dynamic({
    app,
    models: () =>
      models.filter(model => modelNotExisted(app, model)).map(m => import(`../models/${m}.js`)),
    // add routerData prop
    component: () => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return component().then((raw) => {
        const Component = raw.default || raw;
        return props =>
          createElement(Component, {
            ...props,
            routerData: routerDataCache,
          });
      });
    },
  });
};

function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
}

export const getRouterData = (app) => {
  const routerConfig = {
    '/': {
      component: dynamicWrapper(app, ['auth', 'profile', 'errors', 'conversations'], () => import('../layouts/BasicLayout')),
    },

    '/patients/': {
      component: dynamicWrapper(app, ['patients'], () => import('../routes/Patients/')),
    },
    '/patients/:id/profile': {
      component: dynamicWrapper(app, ['patients'], () => import('../routes/Patients/show')),
    },
    '/patients/:id/:tabKey/:resourceId?': {
      component: dynamicWrapper(app, [
        'patients',
        'care_plans',
        'care_plan_activities',
        'contents',
        'conversations',
        'messages',
        'observations',
        'questionnaires',
        'questionnaire_responses',
        'secure_messages',
        'templates',
        'measure_reports',
        'organizations',
        'users',
      ], () => import('../routes/CarePlans')),
    },

    '/print_preview/:type/:print_id?': {
      component: dynamicWrapper(app, ['care_plans', 'contents'], () => import('../routes/PrintPreview/')),
    },

    '/dashboard/': {
      component: dynamicWrapper(app, ['patients'], () => import('../routes/Dashboard/')),
    },

    '/library/contents/:contentType/': {
      component: dynamicWrapper(app, ['contents'], () => import('../routes/Contents/')),
    },
    '/library/contents/:contentType/new': {
      component: dynamicWrapper(app, ['contents', 'resource_tags'], () => import('../routes/Contents/new')),
    },
    '/library/contents/edit/:id': {
      component: dynamicWrapper(app, ['contents', 'resource_tags'], () => import('../routes/Contents/edit')),
    },
    '/library/contents/:contentType/:id': {
      component: dynamicWrapper(app, ['contents', 'resource_tags'], () => import('../routes/Contents/show')),
    },

    '/library/email_templates/': {
      component: dynamicWrapper(app, ['email_templates'], () => import('../routes/EmailTemplates/')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },
    '/library/email_templates/builder/:id': {
      component: dynamicWrapper(app, ['email_templates'], () => import('../routes/EmailTemplates/builder')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },
    '/library/email_templates/preview/:id': {
      component: dynamicWrapper(app, ['email_templates'], () => import('../routes/EmailTemplates/builder')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },
    '/library/email_templates/:id': {
      component: dynamicWrapper(app, ['email_templates'], () => import('../routes/EmailTemplates/show')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },

    '/library/questionnaires/': {
      component: dynamicWrapper(app, ['questionnaires', 'resource_tags'], () => import('../routes/Questionnaires/')),
    },
    '/library/questionnaires/:id': {
      component: dynamicWrapper(app, ['questionnaires', 'questionnaire_items', 'resource_tags'], () => import('../routes/Questionnaires/show')),
    },

    '/library/templates/': {
      component: dynamicWrapper(app, ['templates'], () => import('../routes/Templates/')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },
    '/library/templates/:templateId': {
      component: dynamicWrapper(app, [
        'patients',
        'templates',
        'template_activities',
        'contents',
        'questionnaires',
        'questionnaire_responses',
        'secure_messages',
      ], () => import('../routes/Templates/template')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },


    '/practitioners/': {
      component: dynamicWrapper(app, ['practitioners'], () => import('../routes/Practitioners/')),
    },
    '/practitioners/:id': {
      component: dynamicWrapper(app, ['practitioners'], () => import('../routes/Practitioners/show')),
    },

    '/account/settings/organizations/': {
      component: dynamicWrapper(app, ['organizations', 'accounts'], () => import('../routes/Organizations/')),
    },
    '/account/settings/organizations/:id': {
      component: dynamicWrapper(app, ['organizations', 'accounts'], () => import('../routes/Organizations/show')),
    },

    '/communications/': {
      component: dynamicWrapper(app, ['communications'], () => import('../routes/Communications/')),
    },
    '/communications/edit/:id': {
      component: dynamicWrapper(app, ['communications'], () => import('../routes/Communications/edit')),
    },
    '/communications/new': {
      component: dynamicWrapper(app, ['communications'], () => import('../routes/Communications/new')),
    },
    '/communications/:id': {
      component: dynamicWrapper(app, ['communications'], () => import('../routes/Communications/show')),
    },

    '/locations/': {
      component: dynamicWrapper(app, ['locations'], () => import('../routes/Locations/')),
    },
    '/locations/edit/:id': {
      component: dynamicWrapper(app, ['locations'], () => import('../routes/Locations/edit')),
    },
    '/locations/new': {
      component: dynamicWrapper(app, ['locations'], () => import('../routes/Locations/new')),
    },
    '/locations/:id': {
      component: dynamicWrapper(app, ['locations'], () => import('../routes/Locations/show')),
    },

    '/appointments/': {
      component: dynamicWrapper(app, ['appointments'], () => import('../routes/Appointments/')),
    },
    '/appointments/edit/:id': {
      component: dynamicWrapper(app, ['appointments'], () => import('../routes/Appointments/edit')),
    },
    '/appointments/new': {
      component: dynamicWrapper(app, ['appointments'], () => import('../routes/Appointments/new')),
    },
    '/appointments/:id': {
      component: dynamicWrapper(app, ['appointments'], () => import('../routes/Appointments/show')),
    },

    '/users/': {
      component: dynamicWrapper(app, ['users'], () => import('../routes/Users/')),
    },
    '/users/edit/:id': {
      component: dynamicWrapper(app, ['users'], () => import('../routes/Users/edit')),
    },
    '/users/new': {
      component: dynamicWrapper(app, ['users'], () => import('../routes/Users/new')),
    },
    '/users/:id': {
      component: dynamicWrapper(app, ['users'], () => import('../routes/Users/show')),
    },

    '/media/': {
      component: dynamicWrapper(app, ['media'], () => import('../routes/Media/')),
    },
    '/media/edit/:id': {
      component: dynamicWrapper(app, ['media'], () => import('../routes/Media/edit')),
    },
    '/media/new': {
      component: dynamicWrapper(app, ['media'], () => import('../routes/Media/new')),
    },
    '/media/:id': {
      component: dynamicWrapper(app, ['media'], () => import('../routes/Media/show')),
    },

    '/account/center': {
      component: dynamicWrapper(app, ['profile'], () => import('../routes/Settings')),
    },
    '/exception/403': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/403')),
    },
    '/exception/404': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/404')),
    },
    '/exception/500': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/500')),
    },
    '/exception/trigger': {
      component: dynamicWrapper(app, ['error'], () =>
        import('../routes/Exception/triggerException')),
    },
    '/user': {
      component: dynamicWrapper(app, ['errors'], () => import('../layouts/UserLayout')),
    },
    '/authorize': {
      component: dynamicWrapper(app, ['auth', 'errors'], () => import('../routes/User/Authorize')),
    },
    '/confirm/:id': {
      component: dynamicWrapper(app, ['auth', 'errors'], () => import('../routes/User/Confirm')),
    },
    '/user/login': {
      component: dynamicWrapper(app, ['auth'], () => import('../routes/User/Login')),
    },
    '/user/accept_invitation/:invitationToken': {
      component: dynamicWrapper(app, ['auth'], () => import('../routes/User/AcceptInvitation')),
    },
    '/media_assets/': {
      component: dynamicWrapper(app, ['media_assets'], () => import('../routes/MediaAssets/')),
    },
    '/media_assets/edit/:id': {
      component: dynamicWrapper(app, ['media_assets'], () => import('../routes/MediaAssets/edit')),
    },
    '/media_assets/new': {
      component: dynamicWrapper(app, ['media_assets'], () => import('../routes/MediaAssets/new')),
    },
    '/media_assets/:id': {
      component: dynamicWrapper(app, ['media_assets'], () => import('../routes/MediaAssets/show')),
    },

    '/workflows/': {
      component: dynamicWrapper(app, ['workflows'], () => import('../routes/Workflows/')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },
    '/workflows/:id': {
      component: dynamicWrapper(app, ['workflows', 'contents', 'templates', 'template_activities', 'email_templates', 'questionnaires'], () => import('../routes/TriggeredActions/edit')),
      authority: ['super_admin', 'account_admin', 'admin'],
    },
  };
  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuData());

  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  const combinedRouterConfig = { ...routerConfig };
  // The route matches the menu
  Object.keys(combinedRouterConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find(key => pathRegexp.test(`${key}`));
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = combinedRouterConfig[path];
    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb,
    };
    routerData[path] = router;
  });
  return routerData;
};
