import _ from 'lodash';
import models from '~/models/';
import initialStateResource from './resource';
import initialStateAuth from './auth';
import initialStateErrors from './errors';
import initialStateProfile from './profile';

const resourceStates = _.reduce(models, (result, model) => ({
  ...result,
  [model.default.namespace]: initialStateResource,
}), {});

export default {
  ...resourceStates,
  auth: initialStateAuth,
  errors: initialStateErrors,
  profile: initialStateProfile,
};
