import dva from 'dva';
import createHistory from 'history/createBrowserHistory';
import createLoading from 'dva-loading';
import _ from 'lodash';
import initialStates from '~/models/initialStates';

// 1. Initialize
const app = dva({
  history: createHistory(),
  onError: (e) => {
    e.preventDefault();
    // TODO: Handle App Errors here
  },
  onReducer: reducer => (state, action) => {
    if (action.type === 'RESET') {
      const stateKeys = _.keys(state);
      const resetState = _.pick(initialStates, stateKeys);
      return {
        ...state,
        ...resetState,
      };
    }

    if (action.type === 'SWITCH_ORG') {
      return {
        ...initialStates,
        profile: state.profile,
        loading: state.loading,
      };
    }

    const newState = reducer(state, action);
    return newState;
  },
  onEffect: (effect, { put, select }, model, actionType) => {
    if (!actionType.match(/fetch|read/g)) {
      return function *clearErrorsBeforeEffect(...args) {
        const formErrors = yield select(state => state.errors.formErrors);
        if (formErrors.length > 0) {
          yield put({ type: 'errors/clearErrors' });
        }
        yield effect(...args);
      };
    }
    return effect;
  },
});

// 2. Plugins
app.use(createLoading());

// 3. Register global model
// app.model(require('./models/global').default);

// 4. Router
app.router(require('./router').default);

// console.log('process.env', process.env)
// 5. Start
if (process.env.NODE_ENV !== 'test') {
  app.start('#root');
}

export default app._store; // eslint-disable-line
