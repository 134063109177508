import React from 'react';
import { routerRedux, Route, Switch } from 'dva/router';
import { LocaleProvider, Spin } from 'antd';
import locale from 'antd/lib/locale-provider/en_US';
import dynamic from 'dva/dynamic';
import ErrorBoundary from '~/components/ErrorBoundary';
import ErrorPage from '~/components/ErrorPage';
import { getRouterData } from './common/router';
import Authorized from './utils/Authorized';
import './globals.module.less';

const { ConnectedRouter } = routerRedux;
const { AuthorizedRoute } = Authorized;

const appRoles = process.env.REACT_APP_WHITELISTED_ROLES ? process.env.REACT_APP_WHITELISTED_ROLES.split(',') : ['user', 'admin'];

const loaderStyles = {
  width: '100vh',
  height: '100vh',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

dynamic.setDefaultLoadingComponent(() => (
  <div style={loaderStyles}>
    <Spin size="large" />
  </div>
));

function RouterConfig({ history, app }) {
  const routerData = getRouterData(app);
  const UserLayout = routerData['/user'].component;
  const BasicLayout = routerData['/'].component;

  return (
    <LocaleProvider locale={locale}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/error" component={ErrorPage} />
          <Route path="/user" component={UserLayout} />
          <Route path="/authorize" component={routerData['/authorize'].component} />
          <Route path="/confirm/:id" component={routerData['/confirm/:id'].component} />
          <AuthorizedRoute
            path="/"
            render={props => (
              <ErrorBoundary>
                <BasicLayout {...props} />
              </ErrorBoundary>
            )}
            authority={appRoles}
            redirectPath="/user/login"
          />
        </Switch>
      </ConnectedRouter>
    </LocaleProvider>
  );
}

export default RouterConfig;
