import ApiClient from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '../utils/table';
import { matchPath, redirectToIndex } from '../utils/router';
import initialState from './initialStates/resource';

const resource = 'patients';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery, filters } = payload;
      const response = yield call(ApiClient.patients.search, pageNumber, sortField, sortOrder, searchQuery, filters);
      yield put({
        type: 'readResource',
        payload: {
          ...response,
          filters,
        },
      });
    },
    *create({ payload }, { call, put, select }) {
      const { params } = payload;
      const response = yield call(ApiClient.patients.create, params);
      const pathname = yield select(state => state.routing.location.pathname);
      if (response) {
        yield put({
          type: 'createResource',
          payload: response,
        });
        if (matchPath(pathname, '/patients')) {
          yield put({
            type: 'reload',
          });
          yield put({
            type: 'read',
            payload: response.data,
          });
        } else {
          yield put({
            type: 'read',
            payload: response.data,
          });
        }
      }
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.patients.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put, select }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.patients.update, id, params);
      const pathname = yield select(state => state.routing.location.pathname);
      if (response) {
        yield put({
          type: 'updateResource',
          payload: response.data,
        });
        if (matchPath(pathname, '/patients')) {
          yield put({
            type: 'reload',
          });
          yield put({
            type: 'read',
            payload: response.data,
          });
        } else {
          yield put({
            type: 'read',
            payload: response.data,
          });
        }
      }
    },
    *delete({ payload }, { call, put, select }) {
      const { id } = payload;
      yield call(ApiClient.patients.delete, id);
      const pathname = yield select(state => state.routing.location.pathname);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      if (matchPath(pathname, '/patients')) {
        yield put({
          type: 'fetch',
          payload: { id },
        });
      } else {
        yield call(redirectToIndex, { put, select }, resource);
      }
    },
    *reload(action, { put, select }) {
      const { pageNumber, sortField, sortOrder, searchQuery, filters } = yield select(state => state.patients);
      yield put({ type: 'fetch', payload: { pageNumber, sortField, sortOrder, resource, searchQuery, filters } });
    },
  },
  reducers: {
    readResource(state, action) {
      const { links, filters } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        list: deserialisedData.data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
        filters,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state) {
      return {
        ...state,
      };
    },
    currentResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        resource: deserialisedData.data,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
  },
};
