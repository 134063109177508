/*
  Basic timer class
  Start timer with timer.start(obj)
  Timer will reset and overrwite callback function under key with each #start call
*/

import PropTypes from 'prop-types';

const propTypes = {
  key: PropTypes.string.isRequired,
  cb: PropTypes.func,
  expiresIn: PropTypes.number.isRequired,
};

class Timer {
  static set(key, value) {
    this.timerInstance = this.timerInstance || {};
    this.timerInstance[key] = value;
  }

  static clear(key) {
    const ins = this.get(key);
    if (ins) {
      clearTimeout(ins);
      delete this.timerInstance[key];
    }
  }

  static get(key) {
    return this.timerInstance && this.timerInstance[key];
  }

  static start(obj) {
    PropTypes.checkPropTypes(propTypes, obj, 'prop', 'Timer');
    const { key, cb, expiresIn } = obj;
    this.clear(key);

    const func = () => { if (cb) cb(); };

    const timeout = setTimeout(func, expiresIn);
    this.set(key, timeout);
  }
}

export default Timer;
