import update from 'immutability-helper';
import _ from 'lodash';
import ApiClient from '~/services/client';
import { notification } from 'antd';
import { notificationError } from '~/utils/models';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '../utils/table';
import { redirectToIndex } from '../utils/router';
import initialState from './initialStates/resource';

const resource = 'care_plans';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery } = payload;
      const response = yield call(ApiClient.care_plans.search, pageNumber, sortField, sortOrder, searchQuery);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put, select }) {
      const { params } = payload;
      const response = yield call(ApiClient.care_plans.create, params);
      yield put({
        type: 'createResource',
        payload: response,
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.care_plans.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put, select }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.care_plans.update, id, params);
      yield put({
        type: 'updateResource',
        payload: response.data,
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *delete({ payload }, { call, put, select }) {
      const { id } = payload;
      yield call(ApiClient.care_plans.delete, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *send({ payload }, { call, put }) {
      const { id } = payload;
      try {
        yield call(ApiClient.care_plans.send, id);
        notification.success({
          message: 'Care Plan notification sent.',
        });
      } catch (e) { yield put(notificationError(e)); }
    },
    *print({ payload }, { put }) {
      const { params } = payload;
      yield put({
        type: 'printSelect',
        payload: params,
      });
    },
  },
  reducers: {
    readResource(state, action) {
      const { data, links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      return {
        ...state,
        list: data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
      };
    },
    currentResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        resource: deserialisedData.data,
      };
    },
    deleteResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1]] }),
      };
    },
    printSelect(state, action) {
      return {
        ...state,
        resource: {
          ...state.resource,
          print_preview: action.payload,
        },
      };
    },
  },
};
