import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import Model from '../resource_tags';

const fakeResponse = {
  data: {
    id: '0bfcf774-4f38-11e9-adc9-932b94a9a9a2',
    type: 'tags',
    attributes: {
      context: null,
      created_at: '2019-03-25T19:56:14.944Z',
      created_by: null,
      creator: {},
      id: '0bfcf774-4f38-11e9-adc9-932b94a9a9a2',
      name: '1',
      organization_id: null,
      taggings_count: 2,
      updated_at: '2019-03-25T19:56:14.944Z',
      updated_by: null,
    },
  },
};

describe('Resource Tag Model', () => {
  const fakeResourceTagActionNoRefetch = {
    payload: {
      id: '0bfcf774-4f38-11e9-adc9-932b94a9a9a2',
      resourceType: 'questionnaire_items',
      params: {
        tag_name: '1',
      },
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };

  const fakeResourceTagAction = {
    payload: {
      ...fakeResourceTagActionNoRefetch.payload,
      refetch: {
        type: 'questionnaires/read',
        payload: {
          id: 'abc',
        },
      },
    },
  };

  test('fetch', () => expectSaga(Model.effects.fetch, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.resource_tags.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  test('create with refetch', () => expectSaga(Model.effects.create, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.resource_tags.create), fakeResponse],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .put({
      type: 'questionnaires/read',
      payload: {
        id: fakeResourceTagAction.payload.refetch.payload.id,
      },
    })
    .run());

  test('create without refetch', () => expectSaga(Model.effects.create, fakeResourceTagActionNoRefetch, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.resource_tags.create), fakeResponse],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .not.put({
      type: 'questionnaires/read',
      payload: {
        id: fakeResourceTagAction.payload.refetch.payload.id,
      },
    })
    .run());

  test('delete with refetch', () => expectSaga(Model.effects.delete, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.resource_tags.delete), ''],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeResourceTagAction.payload.id },
    })
    .put({
      type: 'questionnaires/read',
      payload: {
        id: fakeResourceTagAction.payload.refetch.payload.id,
      },
    })
    .run());

  test('delete without refetch', () => expectSaga(Model.effects.delete, fakeResourceTagActionNoRefetch, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.resource_tags.delete), ''],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeResourceTagAction.payload.id },
    })
    .not.put({
      type: 'questionnaires/read',
      payload: {
        id: fakeResourceTagAction.payload.refetch.payload.id,
      },
    })
    .run());
});
