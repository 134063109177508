import initialState from './initialStates/errors';

export default {
  namespace: 'errors',
  state: initialState,
  reducers: {
    addErrors(state, action) {
      const errors = action.payload;
      return {
        ...state,
        formErrors: errors.response.errors,
      };
    },
    clearErrors(state) {
      return {
        ...state,
        formErrors: [],
      };
    },
  },
};
