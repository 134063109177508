export const fakeTriggerResponse = {
  data: {
    attributes: {
      active: false,
      created_at: '2019-04-10T19:49:39.129Z',
      created_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      creator: { first_name: 'bob', last_name: 'User' },
      deleted_at: null,
      domain: 'Patient',
      event: 'created',
      filters: {},
      filters_tree: "['~#'',null]",
      id: 'c6a99e68-5bc9-11e9-9416-2f1d2e4146ed',
      name: null,
      notify: null,
      organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
      timing: {
        count: null,
        count_max: null,
        day_of_week: null,
        duration: null,
        duration_max: null,
        duration_unit: null,
        event: null,
        frequency: null,
        frequency_max: null,
        id: null,
        offset: null,
        period: null,
        period_max: null,
        period_unit: null,
        time_of_day: null,
        when: null,
      },
      updated_at: '2019-04-10T19:49:39.129Z',
      updated_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      updater: { first_name: 'bob', last_name: 'User' },
      workflow_id: '6f3e820a-1e5d-11e9-978c-d359c113a300',
    },
    id: 'c6a99e68-5bc9-11e9-9416-2f1d2e4146ed',
    type: 'triggered_actions',
  },
};
