import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../care_plans';

const fakeResponse = {
  data: {
    id: '096ecb16-a0bc-11e8-b067-b385eaf0486e',
    type: 'care_plans',
    attributes: {
      id: '096ecb16-a0bc-11e8-b067-b385eaf0486e',
      organization_id: '0db4be2c-9e7f-11e8-84cc-6b8bdc7b4d29',
      location_id: null,
      patient_id: null,
      practitioner_id: null,
      status: 'active',
      intent: 'intent',
      title: 'Care Plan',
      description: 'description',
      start_date: '2018-08-15T18:50:05.439Z',
      end_date: '2018-08-31T18:50:07.174Z',
      preferences: {},
      created_at: '2018-08-15T18:50:10.707Z',
      updated_at: '2018-08-15T18:50:10.707Z',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
    },
  },
};

describe('CarePlan Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plans.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        status: 'active',
        intent: 'intent',
        title: 'Care Plan',
        description: 'description',
        start_date: '2018-08-15T18:50:05.439Z',
        end_date: '2018-08-31T18:50:07.174Z',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plans.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plans.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plans.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plans.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
