import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import initialState from './initialStates/profile';

const resource = 'profile';

export default {
  namespace: resource,

  state: initialState,

  effects: {
    *fetch(action, { call, put }) {
      const response = yield call(ApiClient.profile.read);
      yield put({
        type: 'readResource',
        payload: response.data,
      });
    },
    *update({ payload }, { call, put }) {
      const { params } = payload;

      try {
        const response = yield call(ApiClient.profile.update, params);
        yield put({
          type: 'updateResource',
          payload: response.data,
          status: 'success',
        });
        yield put({
          type: 'fetch',
          payload: response,
        });
      } catch (error) {
        yield put({
          type: 'updateResource',
          payload: {},
          status: `Error ${error.response}`,
        });
      }
      yield put(routerRedux.push('/account/center'));
    },
  },

  reducers: {
    readResource(state, { payload: { attributes } }) {
      return {
        ...state,
        currentUser: attributes,
      };
    },
    updateResource(state, { payload, status }) {
      return {
        ...state,
        currentUser: {
          ...payload.attributes,
          status,
        },
      };
    },
  },
};
