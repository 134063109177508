import _ from 'lodash';
import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '../utils/table';
import initialState from './initialStates/resource';

const resource = 'questionnaires';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, pageSize, sortField, sortOrder, filters, searchQuery, extraQueries } = payload;
      const response = yield call(ApiClient.questionnaires.search, {
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        filters,
        searchQuery,
        extraQueries,
      });
      yield put({
        type: 'readResource',
        payload: {
          ...response,
          extraQueries,
        },
      });
    },
    *create({ payload }, { call, put }) {
      const { params, tagParams = {} } = payload;
      const response = yield call(ApiClient.questionnaires.create, params);
      if (response) {
        if (!_.isEmpty(tagParams.tag_name)) {
          yield put.resolve({
            type: 'resource_tags/create',
            payload: {
              id: response.data.id,
              resourceType: resource,
              params: {
                ...tagParams,
              },
            },
          });
        }
        yield put({
          type: 'createResource',
          payload: response,
        });
        yield put(routerRedux.push(`/library/questionnaires/${response.data.id}`));
      }
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.questionnaires.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.questionnaires.update, id, params);
      yield put({
        type: 'updateResource',
        payload: response,
      });
    },
    *delete({ payload }, { call, put }) {
      const { id } = payload;
      yield call(ApiClient.questionnaires.delete, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put({
        type: 'fetch',
        payload: { id },
      });
      yield put(routerRedux.push('/library/questionnaires'));
    },
  },
  reducers: {
    readResource(state, action) {
      const { links, extraQueries } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const deserialisedPayload = deserialise(action.payload);
      return {
        ...state,
        list: deserialisedPayload.data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
        extraQueries,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        resource: deserialisedData.data,
      };
    },
    currentResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        resource: deserialisedData.data,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
  },
};
