import { isUrl } from '../utils/utils';

const menuData = [
  {
    name: 'Participants',
    path: 'patients',
  },
  {
    name: 'Care Team',
    path: 'practitioners',
  },
  {
    name: 'Library',
    path: 'library',
    children: [
      {
        name: 'Education',
        path: 'contents/education',
      },
      // TODO: re-add
      // {
      //   name: 'Exercise',
      //   path: 'contents/exercise',
      // },
      {
        name: 'Email Templates',
        path: 'email_templates',
        authority: ['super_admin', 'account_admin', 'admin'],
      },
      {
        name: 'Questionnaires',
        path: 'questionnaires',
      },
      {
        name: 'Templates',
        path: 'templates',
        authority: ['super_admin', 'account_admin', 'admin'],
      },
    ],
  },
  {
    name: 'Workflows',
    path: 'workflows',
    authority: ['super_admin', 'account_admin', 'admin'],
  },
];

function formatter(data, parentPath = '/', parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}

export const getMenuData = () => formatter(menuData);
