export default {
  namespace: 'secure_messages',
  state: {},
  effects: {
    *sendInvite({ payload }, { put }) {
      yield put.resolve({ type: 'auth/sendInvite', payload });
      yield put({
        type: 'patients/read',
        payload: {
          id: payload.resource_id,
        },
      });
    },
  },
};
