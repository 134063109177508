import { parse, stringify } from 'qs';
import _ from 'lodash';

export function objectKeysToCamelCase(obj) {
  return _.mapKeys(obj, (v, k) => _.camelCase(k));
}

export function objectKeysToSnakeCase(obj) {
  return _.mapKeys(obj, (v, k) => _.snakeCase(k));
}

export function getParameterByName(name, url) {
  const queryString = url.split('?')[1] || url;
  const obj = parse(queryString);
  return _.get(obj, name);
}
export function formatQueryString(pageNumber, pageSize = 25, sortField, sortOrder, searchQuery, extraQueries) {
  const order = sortOrder === 'descend' ? '-' : '';
  const sort = sortOrder && sortField && `${order}${sortField}`;
  const obj = {
    page: {
      number: pageNumber,
      size: pageSize,
    },
    sort,
    filter: searchQuery && searchQuery.length > 0 ? searchQuery : null,
    ...objectKeysToSnakeCase(extraQueries),
  };
  return stringify(obj);
}

export function getPageInfo(links) {
  // Return links if it is already formatted
  if (_.isPlainObject(links) && links.pageNumber) {
    return links;
  }

  const pageNumber = Number(getParameterByName('page.number', links.self));
  const pageSize = Number(getParameterByName('page.size', links.self));
  const lastPage = Number(getParameterByName('page.number', links.last));
  const total = lastPage * pageSize;
  let sortField = null;
  let sortOrder = null;
  if (getParameterByName('sort', links.self)) {
    sortField = getParameterByName('sort', links.self).replace(/-/g, '');
    sortOrder = getParameterByName('sort', links.self).charAt(0) === '-' ? 'descend' : 'ascend';
  }
  const searchQuery = getParameterByName('search', links.self);
  const filters = getParameterByName('filter', links.self);
  return {
    pageNumber,
    pageSize,
    total,
    sortField,
    sortOrder,
    searchQuery,
    filters,
  };
}
