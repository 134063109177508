import _ from 'lodash';
import ApiClient from '~/services/client';
import initialState from './initialStates/resource';
import { getPageInfo } from '../utils/table';

const resource = 'resource_tags';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery } = payload;
      const response = yield call(ApiClient.resource_tags.search, pageNumber, sortField, sortOrder, searchQuery);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { id, resourceType, params, params: { tag_name }, refetch } = payload;
      const transformedParams = {
        ...params,
        tag_name: _.isArray(tag_name) ? tag_name.join(',') : tag_name,
      };
      const response = yield call(ApiClient.resource_tags.create, id, resourceType, transformedParams);
      yield put({
        type: 'createResource',
        payload: response,
      });
      if (refetch) {
        yield put(refetch);
      }
    },
    *delete({ payload }, { call, put }) {
      const { id, resourceType, params, refetch } = payload;
      yield call(ApiClient.resource_tags.delete, id, resourceType, params);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      if (refetch) {
        yield put(refetch);
      }
    },
  },
  reducers: {
    readResource(state, action) {
      const { data, links } = action.payload;
      const { pageNumber, sortField, total, sortOrder, searchQuery } = getPageInfo(links);
      return {
        ...state,
        list: data,
        pageNumber,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
  },
};
