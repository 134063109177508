import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../users';

const fakeResponse = {
  data: {
    id: '2205d752-a17d-11e8-b66f-9bdc97ed9e2c',
    type: 'users',
    attributes: {
      id: '2205d752-a17d-11e8-b66f-9bdc97ed9e2c',
      account_id: null,
      organization_id: 'ddaa7494-101a-11e8-9084-3f4e50f5cca4',
      external_id: null,
      source_id: null,
      username: null,
      picture: null,
      first_name: 'Bob',
      last_name: 'Dobbs',
      phone_number: null,
      email: 'bob@dobbs.com',
      encrypted_password: '$2a$11$TguQMaCylyup8AkaNy82h.Zkis/l0vkCtR87VQtTxP.fOdoRLxdSW',
      reset_password_token: null,
      reset_password_sent_at: null,
      remember_created_at: null,
      sign_in_count: 0,
      current_sign_in_at: null,
      last_sign_in_at: null,
      current_sign_in_ip: null,
      last_sign_in_ip: null,
      confirmation_token: null,
      confirmed_at: '2018-08-16T17:52:26.731Z',
      confirmation_sent_at: null,
      unconfirmed_email: null,
      salt: null,
      created_by: null,
      updated_by: null,
      created_at: '2018-08-16T17:52:24.820Z',
      updated_at: '2018-08-16T17:52:26.731Z',
      invitation_token: '79225307aea7251eab57d0c1c6348bd884013143893e761f1cc5e2c6b2394769',
      invitation_created_at: '2018-08-16T17:52:24.817Z',
      invitation_sent_at: '2018-08-16T17:52:24.817Z',
      invitation_accepted_at: null,
      invitation_limit: null,
      invited_by_id: 1104,
      invited_by_type: null,
      deleted_at: null,
    },
  },
};

describe('Users Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.users.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        organization_id: 'ddaa7494-101a-11e8-9084-3f4e50f5cca4',
        first_name: 'Bob',
        last_name: 'Dobbs',
        phone_number: '5125125125',
        email: 'bob@dobbs.com',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.users.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.users.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.users.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.users.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
