import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import Model from '../email_templates';

const fakeResponse = {
  data: {
    id: '3f2f631a-0f6f-11e9-b0c8-cf5fd30b3b28',
    type: 'email_templates',
    category: null,
    content: '1',
    created_at: '2019-01-03T15:50:08.759Z',
    created_by: null,
    creator: {},
    from: null,
    name: '11',
    organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
    properties: {},
    subject: '1',
    updated_at: '2019-04-10T17:38:00.347Z',
    updated_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
    updater: { first_name: 'bob', last_name: 'User' },
  },
  extraQueries: { contentType: 'Email Templates' },
};

describe('Resource Tag Model', () => {
  const fakeEmailTemplateAction = {
    payload: {
      id: '3f2f631a-0f6f-11e9-b0c8-cf5fd30b3b28',
      pageNumber: 1,
    },
  };

  test('fetch', () => expectSaga(Model.effects.fetch, fakeEmailTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.email_templates.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  test('create', () => expectSaga(Model.effects.create, fakeEmailTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.email_templates.create), fakeResponse],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .put(routerRedux.push('/library/email_templates/builder/3f2f631a-0f6f-11e9-b0c8-cf5fd30b3b28'))
    .run());

  test('read', () => expectSaga(Model.effects.read, fakeEmailTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.email_templates.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  test('update with response', () => expectSaga(Model.effects.update, fakeEmailTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.email_templates.update), fakeResponse],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse,
    })
    .put({ type: 'notifications/success' })
    .put({ type: 'reload' })
    .run());

  test('update with no response', () => expectSaga(Model.effects.update, fakeEmailTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.email_templates.update), null],
    ])
    .not.put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .not.put(routerRedux.push('/library/email_templates'))
    .run());

  test('delete', () => expectSaga(Model.effects.delete, fakeEmailTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.email_templates.delete), ''],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeEmailTemplateAction.payload.id },
    })
    .put({
      type: 'fetch',
      payload: { id: fakeEmailTemplateAction.payload.id },
    })
    .put(routerRedux.push('/library/email_templates'))
    .run());
});
