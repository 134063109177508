import { routerRedux } from 'dva/router';

export function getBasePath(pathname) {
  if (pathname.includes('/admin')) {
    return ('/admin/');
  }
  return '/';
}

export function *redirectToIndex({ put, select }, resource, parentResource, parentId) {
  const pathname = yield select(state => state.routing.location.pathname);
  if (parentResource && parentId) {
    yield put(routerRedux.push(`${getBasePath(pathname)}${parentResource}/${parentId}/${resource}`));
  } else {
    yield put(routerRedux.push(`${getBasePath(pathname)}${resource}`));
  }
}

export function getSelectedPath(pathname) {
  const pathkey = pathname.split('/');
  if (pathname.includes('/admin')) {
    return pathkey[2];
  }
  return pathkey[1];
}

export function getSelectedKey(pathname) {
  return `${getBasePath(pathname)}${getSelectedPath(pathname)}`;
}

export function matchPath(pathname, matcher) {
  return pathname.includes(matcher);
}
