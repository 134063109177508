import fetch from 'dva/fetch';
import { notification } from 'antd';
import { routerRedux } from 'dva/router';
import store from '../index';
import timer from './timer';
import { getCurrentSession } from '../services/auth';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const TTL = parseInt(process.env.REACT_APP_AUTH_TTL, 10);

const codeMessage = {
  200: 'The server successfully returned the requested data. ',
  201: 'The new or modified data was successful. ',
  202: 'A request has been queued in the background (asynchronous task). ',
  204: 'The data was deleted successfully. ',
  400: 'There was an error in the issued request. The server did not create or modify data. ',
  401: 'User does not have permission (token, username, password is wrong). ',
  403: 'The user is authorized but the access is forbidden. ',
  404: 'The request was issued for a non-existent record and the server did not perform the operation. ',
  406: 'The requested format is not available. ',
  410: 'The requested resource is permanently deleted and will not be regained. ',
  422: 'A validation error occurred while creating an object. ',
  500: 'An error occurred on the server. Please check the server. ',
  502: 'The gateway is wrong. ',
  503: 'The service is unavailable, the server is temporarily overloaded or maintained. ',
  504: 'The gateway timed out. ',
};

export async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const jsonResponse = await response.json();
  const errortext = jsonResponse.errors || codeMessage[response.status] || response.statusText;
  if (response.status !== 422 && response.status !== 401) {
    notification.error({
      message: `Request error ${response.status}: ${response.url}`,
      description: errortext,
    });
  }
  const error = new Error(errortext);
  error.status = response.status;
  error.response = jsonResponse;
  throw error;
}

export function handleErrors(errors) {
  const { dispatch } = store;
  const status = errors.status;
  switch (true) {
    case status === 401:
      dispatch({
        type: 'auth/logout',
      });
      break;
    case (status === 422):
      dispatch({
        type: 'errors/addErrors',
        payload: errors,
      });
      break;
    case (status === 403):
      dispatch(routerRedux.push('/exception/403'));
      break;
    case (status <= 504 && status >= 500):
      dispatch(routerRedux.push('/exception/500'));
      break;
    case (status >= 404 && status < 422):
      dispatch(routerRedux.push('/exception/404'));
      break;
    default:
      dispatch(routerRedux.push('/error'));
  }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

export default async function request(resourceType, url, options, isData = true) {
  let session;
  try {
    session = await getCurrentSession();
  } catch (e) {
    store.dispatch({
      type: 'auth/logout',
    });
    return Promise.reject(new Error('Token has expired'));
  }
  const { idToken } = session;

  const defaultOptions = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'PATCH' || newOptions.method === 'DELETE') {
    if (isData) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify({ data: { type: resourceType, attributes: newOptions.body } });
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    }
  }

  return fetch(`${apiUrl}${url}`, newOptions)
    .then(checkStatus)
    .then((response) => {
      timer.start({
        key: 'login',
        cb: () => {
          store.dispatch({
            type: 'auth/logout',
          });
        },
        expiresIn: TTL,
      });
      if (newOptions.method === 'DELETE' || response.status === 204) {
        return response.text();
      }
      return response.json();
    })
    .catch(handleErrors);
}

export async function requestPublic(resourceType, url, options = {}) {
  const newOptions = options;
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'PATCH') {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers,
    };
    newOptions.body = JSON.stringify(newOptions.body);
  }

  return fetch(`${apiUrl}${url}`, newOptions)
    .then(checkStatus)
    .then((response) => {
      if (newOptions.method === 'DELETE' || response.status === 204) {
        return response.text();
      }
      return response.json();
    })
    .catch(handleErrors);
}
