export default {
  list: [],
  resource: {},
  pageNumber: 1,
  pageSize: 25,
  total: 0,
  sortField: null,
  sortOrder: null,
  searchQuery: '',
};
