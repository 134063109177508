export const fakeResponse = {
  data: {
    id: '3c821c18-548c-11e9-87d5-a3028286318b',
    type: 'actions',
    attributes: {
      actions: [
        {
          id: '3c821c18-548c-11e9-87d5-a3028286318b',
          type: 'actions',
        },
      ],
      created_at: '2019-01-22T15:50:25.851Z',
      created_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      creator: { first_name: 'bob', last_name: 'User' },
      deleted_at: null,
      id: '6f3e820a-1e5d-11e9-978c-d359c113a300',
      name: '1',
      organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
      triggered_actions: [
        {
          actions: [
            {
              command: 'Content::Add',
              created_at: '2019-04-01T14:41:29.901Z',
              created_by: null,
              deleted_at: null,
              id: '3c821c18-548c-11e9-87d5-a3028286318b',
              name: null,
              organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
              params: {
                id: null,
                plannable_id: 'f2e12966-0fab-11e9-b871-9713371eebd9',
                plannable_type: 'Content',
                triggered_action_id: '7506d87c-1e5d-11e9-8dcd-a35f6438c74f',
                updated_at: '2019-04-01T14:41:29.901Z',
                updated_by: null,
              },
            },
          ],
          active: true,
          created_at: '2019-01-22T15:50:35.553Z',
          created_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
          creator: { first_name: 'bob', last_name: 'User' },
          deleted_at: null,
          domain: 'Patient',
          event: 'created',
          filters: {},
          filters_tree: "['~#'',null]",
          id: '7506d87c-1e5d-11e9-8dcd-a35f6438c74f',
          name: null,
          notify: null,
          organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
          timing: {
            count: null,
            count_max: null,
            day_of_week: null,
            duration: null,
            duration_max: null,
            duration_unit: null,
            event: null,
            frequency: null,
            frequency_max: null,
            id: null,
            offset: null,
            period: null,
            period_max: null,
            period_unit: null,
            time_of_day: null,
            when: null,
          },
          type: 'triggered_actions',
          updated_at: '2019-01-22T15:50:35.553Z',
          updated_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
          updater: { first_name: 'bob', last_name: 'User' },
          workflow_id: '6f3e820a-1e5d-11e9-978c-d359c113a300',
        },
      ],
      type: 'workflows',
      updated_at: '2019-04-03T20:45:15.961Z',
      updated_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      updater: { first_name: 'bob', last_name: 'User' },
    },
  },
};
