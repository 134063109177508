import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import Model from '../templates';

const fakeResponse = {
  data: {
    id: '4d1fe828-0f6f-11e9-b0c8-6f4b1a251530',
    type: 'care_plans',
    attributes: {
      care_plan_activities: [
        {
          id: '5a3d6d82-5551-11e9-a7a6-5f46f09a0bfd',
          type: 'care_plan_activities',
        },
        {
          id: 'fb45e0b4-5651-11e9-8b0e-9fe9de3f8402',
          type: 'care_plan_activities',
        },
        {
          id: '68912878-5656-11e9-807a-6fb0cba89818',
          type: 'care_plan_activities',
        },
      ],
      category: 'template',
      id: '4d1fe828-0f6f-11e9-b0c8-6f4b1a251530',
      contents: [
        {
          id: 'f2e12966-0fab-11e9-b871-9713371eebd9',
          type: 'contents',
        },
        {
          id: 'f2e7d086-0fab-11e9-b871-97e44f4753d9',
          type: 'contents',
        },
        {
          id: 'f2eacfb6-0fab-11e9-b871-afcc5c06ae20',
          type: 'contents',
        },
      ],
      created_at: '2019-01-03T15:50:32.138Z',
      created_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      creator: {
        first_name: 'bob',
        last_name: 'User',
      },
      description: '1',
      end_date: null,
      episode_of_care_id: null,
      intent: '1',
      location_id: null,
      organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
      patient_id: null,
      practitioner_id: null,
      preferences: {},
      start_date: null,
      status: 'active',
      title: '1',
      type: 'care_plans',
      updated_at: '2019-04-03T21:37:34.033Z',
      updated_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      updater: {
        first_name: 'bob',
        last_name: 'User',
      },
    },
  },
};

describe('Template Model', () => {
  const fakeTemplateAction = {
    payload: {
      id: '4d1fe828-0f6f-11e9-b0c8-6f4b1a251530',
      care_plan_id: 'abc',
      title: '1',
      intent: '1',
      description: '1',
      status: 'active',
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };

  const { payload: { care_plan_id, id } } = fakeTemplateAction;

  test('fetch', () => expectSaga(Model.effects.fetch, fakeTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.templates.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  test('create', () => expectSaga(Model.effects.create, fakeTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.templates.create), fakeResponse],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .put(routerRedux.push(`/library/templates/${id}`))
    .run());

  test('read', () => expectSaga(Model.effects.read, fakeTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.templates.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  test('update', () => expectSaga(Model.effects.update, fakeTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.templates.update), fakeResponse],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  test('delete', () => expectSaga(Model.effects.delete, fakeTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.templates.delete), ''],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeTemplateAction.payload.id },
    })
    .put({
      type: 'fetch',
      payload: { id: fakeTemplateAction.payload.id },
    })
    .put(routerRedux.push('/library/templates'))
    .run());

  test('applyTemplate if response', () => expectSaga(Model.effects.applyTemplate, fakeTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.templates.applyTemplate), fakeResponse],
    ])
    .put({
      type: 'appliedTemplate',
      payload: fakeResponse.data,
    })
    .put({
      type: 'care_plans/read',
      payload: {
        id: care_plan_id,
      },
    })
    .run());

  test('applyTemplate if no response', () => expectSaga(Model.effects.applyTemplate, fakeTemplateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.templates.applyTemplate), null],
    ])
    .not.put({
      type: 'appliedTemplate',
      payload: fakeResponse.data,
    })
    .not.put({
      type: 'care_plans/read',
      payload: {
        id: care_plan_id,
      },
    })
    .run());
});
