import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../media';

const fakeResponse = {
  data: {
    id: '21b97f06-9101-11e8-81f8-dfad92f4689a',
    type: 'media',
    attributes: {
      id: '21b97f06-9101-11e8-81f8-dfad92f4689a',
      organization_id: '3340615c-8168-11e8-bc0f-2b6b8e2bd540',
      created_at: '2018-07-26T18:24:28.156Z',
      updated_at: '2018-07-26T18:24:28.156Z',
      identifier: 'egoistic altruism',
      based_on: null,
      type: 'video',
      sub_type: null,
      view: null,
      subject: null,
      context: null,
      occurence: null,
      operator: null,
      reason_code: null,
      body_site: null,
      device: null,
      height: null,
      width: null,
      frames: null,
      duration: null,
      note: null,
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      content: {
        id: null,
        url: 'https://www.youtube.com/watch?v=rvskMHn0sqQ',
        data: null,
        hash: null,
        size: null,
        title: 'Youtube Egoistic Altruism',
        creation: null,
        language: null,
        content_type: 'video/something',
      },
    },
  },
};

describe('Media Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        identifier: 'egoistic altruism',
        type: 'video',
        content: {
          url: 'https://www.youtube.com/watch?v=rvskMHn0sqQ',
          title: 'Youtube Egoistic Altruism',
          content_type: 'video/something',
        },
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.media.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
