import KeetApi from '@keethealth/api-client';
import { getCurrentSession } from '~/services/auth';
import { handleErrors } from '~/utils/request';
import { getOrganizationId } from '~/utils/user';
import { notification } from 'antd';
import store from '../index';

function logout() {
  store.dispatch({
    type: 'auth/logout',
  });
}

function headers(baseHeaders) {
  return {
    ...baseHeaders,
    'organization-id': getOrganizationId(),
  };
}

export const resourceDefaults = {
  list: [],
  resource: {},
  pageNumber: 1,
  pageSize: 25,
  total: 0,
  sortField: null,
  sortOrder: null,
  searchQuery: '',
  filters: {
    tags: [],
  },
};

const Client = new KeetApi({
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  reactAppSiteUrl: process.env.REACT_APP_SITE_URL,
  API_TIMEOUT: 30 * 1000,
  onUnauthorized: logout,
  getCurrentSession,
  notifyError: notification.error,
  onError: handleErrors,
  headers,
});

export default Client;
