import { call, put, select, race } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import { routerRedux } from 'dva/router';
import { throwError } from 'redux-saga-test-plan/providers';
import { logout, checkForResponse } from '~/services/auth';
import ApiClient from '~/services/client';
import Model from '~/models/auth';

const error = new Error('error');

describe('Auth Model', () => {
  test('check response timeout', () => expectSaga(Model.effects.checkResponse, null, { call, put, race })
    .provide([
      [matchers.call.fn(checkForResponse), throwError({})],
    ])
    .put({
      type: 'changeLoginStatus',
      payload: {
        status: 'error',
        currentAuthority: 'guest',
      },
    })
    .run());

  test('logout', () => expectSaga(Model.effects.logout, null, { call, put, select })
    .provide([
      [matchers.select.like(state => state.routing.location.pathname), 'pathname'],
      [matchers.call.fn(logout)],
    ])
    .put({
      type: 'changeLoginStatus',
      payload: {
        status: false,
        currentAuthority: 'guest',
      },
    })
    .put(routerRedux.push('/user/login'))
    .run());

  const fakeInvitationData = {
    data: {
      username: 'hello',
    },
  };

  const fakeInvitationAction = {
    payload: 'token',
  };

  test('getInvitationData success', () => expectSaga(Model.effects.getInvitationData, fakeInvitationAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.auth.readInvitation), fakeInvitationData],
    ])
    .put({
      type: 'updateInvitationData',
      payload: fakeInvitationData.data,
    })
    .run());

  test('getInvitationData failure', () => expectSaga(Model.effects.getInvitationData, fakeInvitationAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.auth.readInvitation), throwError(error)],
    ])
    .run());

  const fakeAcceptInvitationData = {
    username: 'hello',
  };

  const fakeAcceptInvitationAction = {
    payload: {
      invitationToken: 'token',
      params: {
        username: 'hello',
      },
    },
  };

  test('acceptInvitation success', () => expectSaga(Model.effects.acceptInvitation, fakeAcceptInvitationAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.auth.acceptInvitation), fakeAcceptInvitationData],
    ])
    .put({
      type: 'changeLoginStatus',
      payload: {
        status: true,
        currentAuthority: 'admin',
      },
    })
    .put(routerRedux.push('/user/login'))
    .run());

  test('acceptInvitation failure', () => expectSaga(Model.effects.acceptInvitation, fakeAcceptInvitationAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.auth.acceptInvitation), throwError(error)],
    ])
    .run());

  const fakeResendInvitationAction = {
    payload: {
      email: 'user@example.com',
      role: 'patient',
      redirect_uri: 'http://example.com/callback',
    },
  };

  test('sendInvite success', () => expectSaga(Model.effects.sendInvite, fakeResendInvitationAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.auth.sendInvite)],
    ])
    .run());

  test('sendInvite failure', () => expectSaga(Model.effects.sendInvite, fakeResendInvitationAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.auth.sendInvite), throwError(error)],
    ])
    .run());
});
