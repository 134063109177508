import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../appointments';

const fakeResponse = {
  data: {
    id: '30f68c94-7be8-11e8-a37f-1bf93c70ff5f',
    type: 'appointments',
    attributes: {
      id: '30f68c94-7be8-11e8-a37f-1bf93c70ff5f',
      organization_id: '4c2978f4-7b21-11e8-9547-2b79f6e49fc8',
      identifier: 'Meeting 1',
      reason: 'To have 1 meeting',
      description: 'Describe',
      requested_at: '2018-06-29T22:03:00.718Z',
      started_at: null,
      ended_at: null,
      duration: null,
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      created_at: '2018-06-29T22:03:01.883Z',
      updated_at: '2018-06-29T22:03:01.883Z',
      status: null,
      source: null,
      source_id: null,
    },
  },
};

describe('Appointments Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.appointments.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        description: 'This is a description',
        identifier: 'identifier',
        reason: 'reason',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.appointments.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.appointments.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.appointments.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.appointments.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
