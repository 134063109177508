import { call, put, select } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../practitioners';

const fakeResponse = {
  data: {
    id: 'd9edf130-7b1f-11e8-9547-ab44634ae596',
    type: 'practitioners',
    attributes: {
      id: 'd9edf130-7b1f-11e8-9547-ab44634ae596',
      organization_id: 'ddaa7494-101a-11e8-9084-3f4e50f5cca4',
      given: 'Ardhimas',
      family: 'Kamdani',
      name: '',
      email: 'kamdani.af@gmail.com',
      phone: '5129499499',
      address: '7000 Southcrest Blvd\n103',
      source: null,
      source_id: null,
      active: null,
      gender: 'male',
      birthdate: '1911-06-02',
      language: null,
      properties: null,
      refreshed_at: null,
      deleted_at: null,
      checksum: '',
      created_at: '2018-06-28T22:08:56.446Z',
      updated_at: '2018-06-28T22:08:56.446Z',
      address_city: null,
      address_state: null,
      address_postalcode: null,
      address_country: null,
      created_by: null,
      updated_by: null,
    },
  },
};

describe('Practitioner Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.practitioners.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        given: 'Ardhimas',
        family: 'Kamdani',
        email: 'kamdani.af@gmail.com',
        phone: '5129499499',
        address: '7000 Southcrest Blvd\n103',
        gender: 'male',
        birthdate: '1911-06-02',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.practitioners.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.practitioners.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.practitioners.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };

  const deleteState = {
    routing: {
      location: {
        pathname: '/patients',
      },
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put, select })
    .withState(deleteState)
    .provide([
      [matchers.call.fn(ApiClient.practitioners.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
