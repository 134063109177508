import moment from 'moment';
import _ from 'lodash';

export const validateUploadFormat = (rule, value, callback) => {
  if (value) {
    const { file } = value;
    const allowedTypes = ['image/png', 'image/jpeg', 'image/gif'];

    const isJPG = allowedTypes.includes(file.type);
    if (!isJPG) {
      callback('You can only upload JPG file!');
    }
    const isLt1M = file.size / 1024 / 1024 <= 1;
    if (!isLt1M) {
      callback('Image must be smaller than 1MB!');
    }
  }

  callback();
};

export const validateImageUrl = (rule, value, callback) => {
  const REGEXP = /^https?:\/\/(?:[a-z0-9-]+\.)+[a-z]{2,6}(?:\/[^/#?]+)+\.(?:jpg|gif|png)$/;
  if (value.match(REGEXP)) {
    callback();
  }
  callback('Must enter valid url for image. e.g. jpg');
};

export const validateDate = (rule, value, callback) => {
  if (!moment(value).isValid()) {
    callback('The date you entered is not valid');
  } else {
    callback();
  }
};

export const validatePhone = (rule, value, callback) => {
  if (!_.isEmpty(value) && value.includes('_') && value !== '(___) ___-____' && value.match(/\d/) !== null) {
    callback('The phone you entered is not valid');
  } else {
    callback();
  }
};

export const validateZipCode = (rule, value, callback) => {
  if (!_.isEmpty(value) && value.match(/^\d{5}(-(\d{4}|_{4}))?$/) === null) {
    callback('The zip code you entered is not valid');
  } else {
    callback();
  }
};

export const validateId = (rule, value, callback) => {
  const REGEXP = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  if (_.isEmpty(value) || value.match(REGEXP)) {
    callback();
  }
  callback('Must select an option');
};

export const validateInt = (rule, value, callback) => {
  if (typeof value !== 'undefined' && _.isNumber(value)) {
    callback('The integer you entered is not valid');
  } else {
    callback();
  }
};
