import ApiClient from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '../utils/table';
import { redirectToIndex } from '../utils/router';
import initialState from './initialStates/resource';

const resource = 'practitioners';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery } = payload;
      const response = yield call(ApiClient.practitioners.search, pageNumber, sortField, sortOrder, searchQuery);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { params } = payload;
      const response = yield call(ApiClient.practitioners.create, params);
      if (response) {
        yield put({
          type: 'createResource',
          payload: response,
        });
        yield put({
          type: 'reload',
        });
      }
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.practitioners.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.practitioners.update, id, params);
      if (response) {
        yield put({
          type: 'updateResource',
          payload: response.data,
        });
        yield put({
          type: 'reload',
        });
        yield put({
          type: 'read',
          payload: {
            id: response.data.id,
          },
        });
      }
    },
    *delete({ payload }, { call, put, select }) {
      const { id } = payload;
      yield call(ApiClient.practitioners.delete, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put({
        type: 'reload',
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *reload(action, { put, select }) {
      const { pageNumber, sortField, sortOrder, searchQuery, filters } = yield select(state => state.practitioners);
      yield put({ type: 'fetch', payload: { pageNumber, sortField, sortOrder, resource, searchQuery, filters } });
    },
  },
  reducers: {
    readResource(state, action) {
      const { links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        list: deserialisedData.data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state) {
      return {
        ...state,
      };
    },
    currentResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        resource: deserialisedData.data,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
  },
};
