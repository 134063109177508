import { call, put, select } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../questionnaire_responses';

const fakeResponse = {
  data: {
    id: 'ab15fb50-9b40-11e8-a00b-23f52697d727',
    type: 'questionnaire_responses',
    attributes: {
      id: 'ab15fb50-9b40-11e8-a00b-23f52697d727',
      organization_id: '3340615c-8168-11e8-bc0f-2b6b8e2bd540',
      url: 'this is an url',
      identifier: 'identifier',
      questionnaire_id: '5e44c160-99ad-11e8-b805-e31166e9e706',
      status: 'published',
      subject: 'Subjectddd',
      context: 'contextss',
      authored: null,
      author: null,
      source: null,
      items: null,
      created_at: '2018-08-08T19:24:28.534Z',
      updated_at: '2018-08-08T20:34:43.348Z',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
    },
  },
};

describe('QuestionnaireResponses Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
      parentId: '5e44c160-99ad-11e8-b805-e31166e9e706',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaire_responses.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: {
        ...fakeResponse,
        extraQueries: undefined,
      },
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        url: 'this is an url',
        identifier: 'identifier',
        status: 'published',
        subject: 'Subjectddd',
        context: 'contextss',
      },
      parentId: '5e44c160-99ad-11e8-b805-e31166e9e706',
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.questionnaire_responses.create), fakeResponse],
      [matchers.select.like(state => state.carePlans.resource.id), '5e44c161-99ad-11e8-b805-e31166e9e706'],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
      parentId: '5e44c160-99ad-11e8-b805-e31166e9e706',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaire_responses.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
      parentId: '5e44c160-99ad-11e8-b805-e31166e9e706',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaire_responses.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaire_responses.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
