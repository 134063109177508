export const fakeActionResponse = {
  data: {
    attributes: {
      command: 'Notification::Send',
      created_at: '2019-04-10T20:35:52.750Z',
      created_by: null,
      creator: {},
      deleted_at: null,
      id: '3bdfe114-5bd0-11e9-9a36-dfcca4b58776',
      name: null,
      organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
      params: {
        email_template_id: '3f2f631a-0f6f-11e9-b0c8-cf5fd30b3b28',
        from: null,
        id: null,
        subject: null,
      },
      triggered_action_id: '37bcf5b8-5bd0-11e9-9a36-6ba0417df5e2',
      updated_at: '2019-04-10T20:35:52.750Z',
      updated_by: null,
      updater: {},
    },
    id: '3bdfe114-5bd0-11e9-9a36-dfcca4b58776',
    type: 'actions',
  },
};
