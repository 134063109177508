import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../locations';

const fakeResponse = {
  data: {
    id: 'aba2910e-7be3-11e8-a37f-17ee9cff44a1',
    type: 'locations',
    attributes: {
      id: 'aba2910e-7be3-11e8-a37f-17ee9cff44a1',
      organization_id: '4c2978f4-7b21-11e8-9547-2b79f6e49fc8',
      name: 'The Office',
      address: '201 E 6th St',
      address_city: null,
      address_country: null,
      address_postalcode: null,
      address_state: null,
      address_use: null,
      endpoint: null,
      identifier: null,
      status: 'active',
      location_type: null,
      preferences: {},
      created_at: '2018-06-29T21:30:40.212Z',
      updated_at: '2018-07-03T18:36:48.564Z',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      source: null,
      source_id: null,
      telecom: {},
    },
  },
};

describe('Location Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.locations.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        name: 'The Office',
        address: '201 E 6th St',
        status: 'active',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.locations.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.locations.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.locations.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.locations.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
