import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import { deserialise } from '~/utils/jsonApi/deserialise';
import Model from '../questionnaires';

const fakeResponse = {
  data: {
    id: '5e44c160-99ad-11e8-b805-e31166e9e706',
    type: 'questionnaires',
    attributes: {
      id: '5e44c160-99ad-11e8-b805-e31166e9e706',
      organization_id: '3340615c-8168-11e8-bc0f-2b6b8e2bd540',
      url: 'https://i.imgur.com/IG3oynV.png',
      identifier: 'IDENTIFIER',
      version: 'v1',
      name: 'Questionnaire Name',
      status: 'draft',
      description: 'Describe',
      purpose: 'Purpose',
      subject_type: 'Subject Type',
      use_context: 'Use Context?',
      items: null,
      created_at: '2018-08-06T19:17:32.474Z',
      updated_at: '2018-08-08T20:32:54.339Z',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
    },
  },
  extraQueries: undefined,
};

const fakeDeserialisedResponse = deserialise(fakeResponse);

describe('Questionnaires Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaires.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        url: 'https://i.imgur.com/IG3oynV.png',
        identifier: 'IDENTIFIER',
        version: 'v1',
        name: 'Questionnaire Name',
        status: 'draft',
        description: 'Describe',
        purpose: 'Purpose',
        subject_type: 'Subject Type',
        use_context: 'Use Context?',
      },
    },
  };

  describe('create', () => {
    describe('when params include tags', () => {
      test('it creates tags after resource creation', () => expectSaga(Model.effects.create, { payload: {
        params: fakeCreateAction.payload.params,
        tagParams: {
          tag_name: ['tag'],
        },
      } }, { call, put })
        .provide([
          [matchers.call.fn(ApiClient.questionnaires.create), fakeResponse],
          [matchers.call.fn(redirectToIndex)],
        ])
        .put.resolve({
          type: 'resource_tags/create',
          payload: {
            id: fakeResponse.data.id,
            resourceType: 'questionnaires',
            params: {
              tag_name: ['tag'],
            },
          },
        })
        .put({
          type: 'createResource',
          payload: fakeResponse,
        })
        .run());
    });

    describe('when params do not include tags', () => {
      test('it creates resource', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
        .provide([
          [matchers.call.fn(ApiClient.questionnaires.create), fakeResponse],
          [matchers.call.fn(redirectToIndex)],
        ])
        .put({
          type: 'createResource',
          payload: fakeResponse,
        })
        .run());
    });
  });

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaires.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeDeserialisedResponse,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaires.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaires.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
