import React, { Fragment } from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import _ from 'lodash';
import { parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import TagsShow from '~/components/TagsShow';
import { validateId } from './formValidators';
import styles from './utils.module.less';

const confirm = Modal.confirm;

export const isTextSelected = () => window.getSelection().toString().length > 0;

export function formatDate(date) {
  if (!moment(date).isValid()) {
    return 'N/A';
  }
  return moment(date).format('MM/DD/YYYY');
}

export function formatBirthDate(date) {
  if (!moment(date).isValid()) {
    return 'N/A';
  }
  return moment(date).format('YYYY-MM-DD');
}

export function formatBirthDateSearch(date) {
  if (!moment(date).isValid()) {
    return '';
  }
  return moment(date).format('YYYY-MM-DD');
}

export function formatPhoneNumber(number) {
  if (number) {
    number.replace(/\D/g, '');
    const phoneNumber = parsePhoneNumber(number, 'US');
    return phoneNumber.formatNational();
  }
  return '';
}

export const formatZipCode = zipcode => (zipcode && zipcode.replace(/-(_{4})/g, ''));

export const search = _.debounce((query, onSearch) => {
  onSearch(query);
}, 500);

export const delayResourceSearch = onSearch => () => search(null, onSearch);

export const delaySearch = (onSearch, queryLength = 3) => (event) => {
  const query = event.target.value;

  if (query.length === 0) {
    onSearch();
  } else if (query.length >= queryLength) {
    event.persist();
    search(query, onSearch);
  }
};

export const delayAutocompleteSearch = onSearch => (value) => {
  if (value.length === 0) {
    onSearch();
  } else if (value.length >= 3) {
    search(value, onSearch);
  }
};

export const showConfirm = (onOk, message = 'Deleting a resource is an irreversible action.') => () => {
  confirm({
    title: 'Are you sure?',
    content: message,
    onOk,
    onCancel() {},
    iconType: 'exclamation-circle',
  });
};

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function accMul(arg1, arg2) {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  m += s1.split('.').length > 1 ? s1.split('.')[1].length : 0;
  m += s2.split('.').length > 1 ? s2.split('.')[1].length : 0;
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / 10 ** m;
}

export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟', '万']];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(accMul(num, 10 * 10 ** index)) % 10] + item).replace(/零./, '');
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path,
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export function isUrl(path) {
  return reg.test(path);
}

export function isDate(value) {
  return _.isString(value) && moment(value, ['MM/DD/YYYY', moment.ISO_8601], true).isValid();
}

export function isPhoneNumber(value) {
  return _.isString(value) && !!parsePhoneNumberFromString(value, 'US');
}

export function isZipCode(value) {
  return _.isString(value) && value.match(/^\d{5}(-(\d{4}|_{4}))?$/) !== null;
}

export function renderJSON(collection) {
  return _.map(collection, (value, key) => (
    `${key}: ${value}`
  ));
}

export function renderUpdater(value) {
  return `${value.first_name || ''} ${value.last_name || ''}`;
}

export function renderTags(value) {
  return (
    <TagsShow value={value} />
  );
}

export function renderAttribute(value, key) {
  const normalizedKey = _.lowerCase(key);
  if (normalizedKey === 'updater' || normalizedKey === 'creator') {
    return renderUpdater(value);
  } else if (normalizedKey === 'tags') {
    return renderTags(value);
  } else if (_.isObject(value) || _.isArray(value)) {
    return renderJSON(value);
  } else if (isDate(value)) {
    return formatDate(value);
  } else if (isZipCode(value)) {
    return value;
  } else if (isPhoneNumber(value)) {
    return formatPhoneNumber(value);
  }
  return value;
}

/* finds first item in collection where predicate returns true and replaces it
* args:
*   collection: Array
*   predicate: Function that takes an element of array and returns a boolean.
*   replace: Function that takes an element of array and returns an object
*
* returns: new array with item replaced at where predicate returns true
*/
export function findAndReplace(collection, predicate, replace) {
  if (!_.isArray(collection)) {
    throw new Error('Argument[0] must be of type Array');
  }

  const idx = _.findIndex(collection, predicate);
  if (idx < 0) return collection;

  const newObj = replace(collection[idx]);

  return [
    ...collection.slice(0, idx),
    newObj,
    ...collection.slice(idx + 1, collection.length),
  ];
}

export function getItemValue(item, key, defaultValue = '') {
  return item ? item[key] : defaultValue;
}

export function checkAuthority(currentAuthority, authority) {
  if (Array.isArray(authority)) {
    let roles = currentAuthority;
    if (typeof currentAuthority === 'string') {
      roles = currentAuthority.split(',');
    }
    const inclusive_roles = _.intersection(authority, roles);

    /*
      TODO: this is a hack for having multiple roles
    */
    if ((authority.indexOf(currentAuthority) >= 0) || (inclusive_roles.length > 0)) {
      return true;
    }
    return false;
  }

  // string 处理
  if (typeof authority === 'string') {
    if (authority === currentAuthority) {
      return true;
    }
    return false;
  }
  return false;
}

export function getDateItemValue(resource, attribute) {
  const resourceDate = getItemValue(resource, attribute);
  return (_.isEmpty(_.trim(resourceDate)) ? undefined : moment(resourceDate));
}

export const initializeFormValues = (mapFields = undefined) =>
  (formItems, resource) => {
    const items = _.cloneDeep(formItems);
    const values = _.map(items, (field) => {
      let initialValue;
      switch (field.type) {
        case 'date':
        case 'datetime':
          initialValue = getDateItemValue(resource, field.name);
          break;
        case 'tagField':
          initialValue = getItemValue(resource, field.name, []);
          break;
        case 'select':
          if (field.options && field.options.mode === 'multiple') {
            initialValue = getItemValue(resource, field.name, []);
          } else {
            initialValue = getItemValue(resource, field.name, null);
          }
          break;
        case 'switch':
          initialValue = getItemValue(resource, field.name, false);
          break;
        default:
          initialValue = getItemValue(resource, field.name);
      }
      _.merge(field, { options: { initialValue } });
      return field;
    });

    if (mapFields) {
      const fieldProps = mapFields(resource);
      _.forEach(fieldProps, (fieldProp) => {
        const field = _.find(values, ['name', fieldProp.name]);
        _.merge(field, fieldProp);
      });
    }

    return values;
  };

export const renderWithDividers = (array) => {
  const filterArray = _.filter(array, Boolean);

  if (!filterArray) {
    return <noscript />;
  }

  return (
    <Fragment>
      {filterArray.map((arrayItem, i) => (
        <span key={arrayItem}>
          {arrayItem}{i < filterArray.length - 1 && (<span className={styles.lines} />)}
        </span>
      ))}
    </Fragment>
  );
};

export function mergeOptions(type, options) {
  const updatedOptions = _.cloneDeep(options);
  const customizer = (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
    return undefined;
  };
  switch (type) {
    case 'selectSearch':
      return _.mergeWith(
        updatedOptions,
        {
          rules: [
            {
              validator: validateId,
            },
          ],
        },
        customizer,
      );
    default:
      return updatedOptions;
  }
}
