import _ from 'lodash';
import ApiClient from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';

const resource = 'measure_reports';

export default {
  namespace: resource,
  state: {},
  effects: {
    *fetch({ payload }, { all, call, put }) {
      const { extraQueries } = payload;
      const getReportParamsFor = (identifier, pageSize = 1) => ({
        pageNumber: 1,
        pageSize,
        sortField: 'ended_at',
        sortOrder: 'descend',
        filters: {
          identifier,
        },
        searchQuery: undefined,
        extraQueries,
      });

      const getTotalHealthIndex = call(ApiClient.measure_reports.search, getReportParamsFor('total_health_index', 10));
      const getHealthIndexMap = _.map([
        'confidence',
        'readiness',
        'disease_index',
        'stress_index',
        'movement_quality',
        'fitness_index',
        'nutrition_index',
      ], index_category => call(ApiClient.measure_reports.search, getReportParamsFor(index_category, 10)));

      const progressReport = yield all([
        getTotalHealthIndex,
        ...getHealthIndexMap,
      ]);

      const [
        totalHealthIndex,
        confidence,
        readiness,
        diseaseIndex,
        stressIndex,
        movementQuality,
        fitnessIndex,
        nutritionIndex,
      ] = progressReport;

      yield put({
        type: 'readResource',
        payload: {
          totalHealthIndex,
          confidence,
          readiness,
          diseaseIndex,
          stressIndex,
          movementQuality,
          fitnessIndex,
          nutritionIndex,
        },
      });
    },
  },
  reducers: {
    readResource(state, action) {
      const healthMap = _.reduce(action.payload, (sum, value, key) => ({
        ...sum,
        [key]: deserialise(value).data,
      }), {});

      return {
        ...state,
        ...healthMap,
      };
    },
  },
};
