import ApiClient, { resourceDefaults as initialState } from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '~/utils/table';

const resource = 'messages';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { conversationId, pageNumber, pageSize, sortField, sortOrder, filters, searchQuery, extraQueries } = payload;
      const response = yield call(ApiClient.messages.search, {
        conversationId,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        filters,
        searchQuery,
        extraQueries,
      });
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { conversationId, params, extraQueries } = payload;
      const response = yield call(ApiClient.messages.create, conversationId, params, extraQueries);
      yield put({
        type: 'createResource',
        payload: response,
      });
      yield put({ type: 'reload' });
      yield put({
        type: 'fetch',
        payload: {
          conversationId,
          extraQueries,
        },
      });
    },
    *read({ payload }, { call, put }) {
      const { conversationId, id } = payload;
      const response = yield call(ApiClient.messages.read, conversationId, id);
      yield put({
        type: 'currentResource',
        payload: response.data,
      });
    },
    *update({ payload }, { call, put }) {
      const { conversationId, id, params } = payload;
      const response = yield call(ApiClient.messages.update, conversationId, id, params);
      if (response) {
        yield put({
          type: 'updateResource',
          payload: response.data,
        });
        yield put({
          type: 'conversations/fetchLatest',
          payload: {},
        });
      }
    },
    *delete({ conversationId, payload }, { call, put }) {
      const { id } = payload;
      yield call(ApiClient.messages.delete, conversationId, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put({
        type: 'fetch',
        payload: { id },
      });
    },
    *reset(action, { put }) {
      yield put({
        type: 'resetState',
      });
    },
  },
  reducers: {
    readResource(state, action) {
      const { links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const { data } = deserialise(action.payload);
      return {
        ...state,
        list: data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state) {
      return {
        ...state,
      };
    },
    currentResource(state, action) {
      const { data } = deserialise(action.payload);
      return {
        ...state,
        resource: data,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
    resetState() {
      return initialState;
    },
  },
};
