import { call, put, select } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import { fakeResponse } from '~/mocks/workflows/workflows_fakeResponse';
import { fakeTriggerResponse } from '~/mocks/workflows/workflows_fakeTriggerResponse';
import { fakeActionResponse } from '~/mocks/workflows/workflows_fakeActionResponse';
import Model from '../workflows';

describe('Workflows Model', () => {
  const fakeWorkflowsAction = { payload: { id: '3c821c18-548c-11e9-87d5-a3028286318b' } };

  const fakeTriggerAction = {
    payload: {
      trigger: {
        active: false,
        domain: 'Patient',
        event: 'created',
        filters: {},
        filters_tree: "['~#'',null]",
        name: undefined,
        workflow_id: '6f3e820a-1e5d-11e9-978c-d359c113a300',
      },
    },
  };

  const fakeAction = {
    payload: {
      action: {
        command: 'Notification::Send',
        name: undefined,
        params: {
          content: undefined,
          email_template_id: '3f2f631a-0f6f-11e9-b0c8-cf5fd30b3b28',
          from: undefined,
          subject: undefined,
        },
        workflow_id: '6f3e820a-1e5d-11e9-978c-d359c113a300',
        triggered_action_id: '37bcf5b8-5bd0-11e9-9a36-6ba0417df5e2',
      },
    },
  };

  test('fetch', () => expectSaga(Model.effects.fetch, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  test('create with response', () => expectSaga(Model.effects.create, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.create), fakeResponse],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .put(routerRedux.push(`/workflows/${fakeResponse.data.id}`))
    .run());

  test('create with no response', () => expectSaga(Model.effects.create, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.create), null],
    ])
    .not.put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .not.put(routerRedux.push(`/workflows/${fakeResponse.data.id}`))
    .run());

  test('read', () => expectSaga(Model.effects.read, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  test('update with response', () => expectSaga(Model.effects.update, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.update), fakeResponse],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .put({
      type: 'read',
      payload: fakeResponse.data,
    })
    .run());

  test('update with no response', () => expectSaga(Model.effects.update, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.update), null],
    ])
    .not.put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .not.put({
      type: 'read',
      payload: fakeResponse.data,
    })
    .run());

  test('delete', () => expectSaga(Model.effects.delete, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeWorkflowsAction.payload.id },
    })
    .put({
      type: 'fetch',
      payload: { id: fakeWorkflowsAction.payload.id },
    })
    .run());

  test('fetchOptions', () => expectSaga(Model.effects.fetchOptions, fakeWorkflowsAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.workflows.options), fakeResponse],
    ])
    .put({
      type: 'fetchedOptions',
      payload: fakeResponse,
    })
    .run());

  test('clearResource', () => expectSaga(Model.effects.clearResource, fakeWorkflowsAction, { call, put })
    .put({
      type: 'clearedResource',
    })
    .run());

  test('createTrigger with response', () => expectSaga(Model.effects.createTrigger, fakeTriggerAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.triggered_actions.create), fakeTriggerResponse],
    ])
    .put({
      type: 'read',
      payload: {
        id: fakeTriggerAction.payload.trigger.workflow_id,
      },
    })
    .run());

  test('createTrigger with no response', () => expectSaga(Model.effects.createTrigger, fakeTriggerAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.triggered_actions.create), null],
    ])
    .not.put({
      type: 'read',
      payload: {
        id: fakeTriggerAction.payload.trigger.workflow_id,
      },
    })
    .run());

  test('updateTrigger with response', () => expectSaga(Model.effects.updateTrigger, fakeTriggerAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.triggered_actions.update), fakeTriggerResponse],
    ])
    .put({
      type: 'read',
      payload: {
        id: fakeTriggerAction.payload.trigger.workflow_id,
      },
    })
    .run());

  test('updateTrigger with no response', () => expectSaga(Model.effects.updateTrigger, fakeTriggerAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.triggered_actions.update), null],
    ])
    .not.put({
      type: 'read',
      payload: {
        id: fakeTriggerAction.payload.trigger.workflow_id,
      },
    })
    .run());

  test('deleteTrigger', () => expectSaga(Model.effects.deleteTrigger, fakeTriggerAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.triggered_actions.delete), ''],
      [matchers.select.like(state => state.workflows.resource.id), fakeTriggerAction.payload.trigger.workflow_id],
    ])
    .put({
      type: 'read',
      payload: {
        id: fakeTriggerAction.payload.trigger.workflow_id,
      },
    })
    .run());

  test('createAction with response', () => expectSaga(Model.effects.createAction, fakeAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.actions.create), fakeActionResponse],
      [matchers.select.like(state => state.workflows.resource.id), fakeAction.payload.action.workflow_id],
    ])
    .put({
      type: 'read',
      payload: {
        id: fakeAction.payload.action.workflow_id,
      },
    })
    .run());

  test('createAction with no response', () => expectSaga(Model.effects.createAction, fakeAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.actions.create), null],
      [matchers.select.like(state => state.workflows.resource.id), fakeAction.payload.action.workflow_id],
    ])
    .not.put({
      type: 'read',
      payload: {
        id: fakeAction.payload.action.workflow_id,
      },
    })
    .run());

  test('updateAction with response', () => expectSaga(Model.effects.updateAction, fakeAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.actions.update), fakeActionResponse],
      [matchers.select.like(state => state.workflows.resource.id), fakeAction.payload.action.workflow_id],
    ])
    .put({
      type: 'read',
      payload: {
        id: fakeAction.payload.action.workflow_id,
      },
    })
    .run());

  test('updateAction with no response', () => expectSaga(Model.effects.updateAction, fakeAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.actions.update), null],
      [matchers.select.like(state => state.workflows.resource.id), fakeAction.payload.action.workflow_id],
    ])
    .not.put({
      type: 'read',
      payload: {
        id: fakeAction.payload.action.workflow_id,
      },
    })
    .run());

  test('deleteAction', () => expectSaga(Model.effects.deleteAction, fakeAction, { call, put, select })
    .provide([
      [matchers.call.fn(ApiClient.actions.delete), ''],
      [matchers.select.like(state => state.workflows.resource.id), fakeAction.payload.action.workflow_id],
    ])
    .put({
      type: 'read',
      payload: {
        id: fakeAction.payload.action.workflow_id,
      },
    })
    .run());
});
