export function notificationError(e) {
  return {
    type: 'notifications/error',
    description: `Error: ${e.message}`,
  };
}

export function formatResourceType(resource) {
  if (resource === 'exercises' || resource === 'education') {
    return 'contents';
  }
  return resource;
}

export function getPlannableType(type) {
  switch (type) {
    case 'Education':
    case 'Exercise':
      return 'Content';
    case 'Questionnaire':
      return 'Questionnaire';
    case 'Internal Questionnaire':
      return 'Questionnaire';
    default:
      return type;
  }
}
