import ApiClient from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { notificationError } from '~/utils/models';
import { getPageInfo } from '~/utils/table';
import initialState from './initialStates/resource';

const resource = 'accounts';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, pageSize, sortField, sortOrder, filters, searchQuery, extraQueries } = payload;
      const searchOptions = {
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        filters,
        searchQuery,
        extraQueries,
      };
      const response = yield call(ApiClient.accounts.search, searchOptions);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { params } = payload;
      try {
        const response = yield call(ApiClient.accounts.create, params);
        yield put({
          type: 'createResource',
          payload: response,
        });
        yield put({ type: 'notifications/success' });
        yield put({
          type: 'fetch',
          payload: response,
        });
      } catch (e) { yield put(notificationError(e)); }
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.accounts.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      const { id, params } = payload;
      try {
        const response = yield call(ApiClient.accounts.update, id, params);
        yield put({
          type: 'updateResource',
          payload: response,
        });
        yield put({ type: 'notifications/success' });
        yield put({
          type: 'fetch',
          payload: response,
        });
      } catch (e) { yield put(notificationError(e)); }
    },
    *delete({ payload }, { call, put }) {
      const { id } = payload;
      try {
        yield call(ApiClient.accounts.delete, id);
        yield put({
          type: 'deleteResource',
          payload: { id },
        });
        yield put({ type: 'notifications/success' });
        yield put({
          type: 'fetch',
          payload: { id },
        });
      } catch (e) { yield put(notificationError(e)); }
    },
  },
  reducers: {
    readResource(state, action) {
      const { links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const { data } = deserialise(action.payload);
      return {
        ...state,
        list: data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state) {
      return {
        ...state,
      };
    },
    currentResource(state, action) {
      const { data } = deserialise(action.payload);
      return {
        ...state,
        resource: data,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
  },
};
