import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import Model from '../questionnaire_items';

const fakeResponse = {
  data: {
    id: 'b7f5cecc-564d-11e9-8b0e-775d37d6baf3',
    type: 'questionnaire_items',
    attributes: {
      id: 'b7f5cecc-564d-11e9-8b0e-775d37d6baf3',
      created_at: '2019-04-03T20:19:01.052Z',
      created_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      creator: {
        first_name: 'bob',
        last_name: 'User',
      },
      definition: 'adsf',
      item_type: 'integer',
      max_length: null,
      ordinal_value: null,
      organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
      position: 1,
      questionnaire_answers: [],
      questionnaire_id: '5e44c160-99ad-11e8-b805-e31166e9e706',
      read_only: false,
      repeats: false,
      required: false,
      tag_list: null,
      tags: [
        'e',
      ],
      text: '1',
      type: 'questionnaire_items',
      updated_at: '2019-04-04T16:55:30.372Z',
      updated_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      updater: {
        first_name: 'bob',
        last_name: 'User',
      },
    },
  },
};

describe('QuestionnaireItems Model', () => {
  const tagParams = {
    tag_name: '1',
  };

  const fakeQuestionnaireItemAction = {
    payload: {
      id: 'b7f5cecc-564d-11e9-8b0e-775d37d6baf3',
      parentId: '5e44c160-99ad-11e8-b805-e31166e9e706',
      item_type: 'integer',
      definition: 'adsf',
      text: '1',
      required: false,
      options: [],
      repeats: false,
      read_only: false,
    },
  };

  const { id, parentId } = fakeQuestionnaireItemAction.payload;
  const { tag_name } = tagParams;

  describe('create', () => {
    describe('create with response', () => {
      describe('when tag params given', () => {
        test('creates resource and creates tags', () => expectSaga(Model.effects.create, {
          payload: {
            ...fakeQuestionnaireItemAction.payload,
            tagParams,
          },
        }, { call, put })
          .provide([
            [matchers.call.fn(ApiClient.questionnaire_items.create), fakeResponse],
          ])
          .put.resolve({
            type: 'resource_tags/create',
            payload: {
              id,
              resourceType: 'questionnaire_items',
              params: {
                tag_name,
              },
            },
          })
          .put({
            type: 'createResource',
            payload: fakeResponse,
          })
          .put({
            type: 'questionnaires/read',
            payload: {
              id: parentId,
            },
          })
          .run());
      });

      describe('when tag params not given', () => {
        test('creates resource and creates tags', () => expectSaga(Model.effects.create, fakeQuestionnaireItemAction, { call, put })
          .provide([
            [matchers.call.fn(ApiClient.questionnaire_items.create), fakeResponse],
          ])
          .not.put.resolve({
            type: 'resource_tags/create',
            payload: {
              id,
              resourceType: 'questionnaire_items',
              params: {
                tag_name,
              },
            },
          })
          .put({
            type: 'createResource',
            payload: fakeResponse,
          })
          .put({
            type: 'questionnaires/read',
            payload: {
              id: parentId,
            },
          })
          .run());
      });
    });

    describe('create without response', () => {
      test('does not call tags/create', () => expectSaga(Model.effects.create, {
        payload: {
          ...fakeQuestionnaireItemAction.payload,
          tagParams,
        },
      }, { call, put })
        .provide([
          [matchers.call.fn(ApiClient.questionnaire_items.create), null],
        ])
        .not.put.resolve({
          type: 'resource_tags/create',
          payload: {
            id,
            resourceType: 'questionnaire_items',
            params: {
              tag_name,
            },
          },
        })
        .not.put({
          type: 'createResource',
          payload: fakeResponse,
        })
        .not.put({
          type: 'questionnaires/read',
          payload: {
            id: parentId,
          },
        })
        .run());
    });
  });

  test('update', () => expectSaga(Model.effects.update, fakeQuestionnaireItemAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaire_items.update), fakeResponse],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .put({
      type: 'questionnaires/read',
      payload: {
        id: parentId,
      },
    })
    .run());

  test('delete', () => expectSaga(Model.effects.delete, fakeQuestionnaireItemAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.questionnaire_items.delete), ''],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeQuestionnaireItemAction.payload.id },
    })
    .put({
      type: 'questionnaires/read',
      payload: {
        id: parentId,
      },
    })
    .run());
});
