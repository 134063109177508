import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import Model from '~/models/profile';

const fakeResponse = {
  data: {
    id: '1104ecee-101a-11e8-9214-bfbfdcb91301',
    type: 'users',
    attributes: {
      id: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      account_id: null,
      organization_id: '10e3701e-101a-11e8-9214-c7cfc0894259',
      external_id: null,
      source_id: null,
      username: 'ardy',
      picture: null,
      first_name: 'Ardhimase',
      last_name: 'Kamdanieee',
      phone_number: '5129499098',
      email: 'user@example.com',
      encrypted_password: '$2a$11$RDTu6ZlNb.r2mnz0ay546uKv/0IiL1xhjdFPHdnTO.RSEh3jwrBnK',
      reset_password_token: null,
      reset_password_sent_at: null,
      remember_created_at: null,
      sign_in_count: 0,
      current_sign_in_at: null,
      last_sign_in_at: null,
      current_sign_in_ip: null,
      last_sign_in_ip: null,
      confirmation_token: 'wyK3-TbVHpS81GSXTv3E',
      confirmed_at: '2018-02-12T17:27:57.532Z',
      confirmation_sent_at: '2018-07-03T18:59:42.365Z',
      unconfirmed_email: 'af.kamdani@gmail.com',
      salt: null,
      created_by: null,
      updated_by: null,
      created_at: '2018-02-12T17:27:57.551Z',
      updated_at: '2018-07-11T18:18:50.946Z',
      invitation_token: null,
      invitation_created_at: null,
      invitation_sent_at: null,
      invitation_accepted_at: null,
      invitation_limit: null,
      invited_by_id: null,
      invited_by_type: null,
      deleted_at: null,
      status: 'success',
    },
  },
};

describe('Auth Model', () => {
  test('fetch', () => expectSaga(Model.effects.fetch, null, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.profile.read), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      params: {
        username: 'hello',
      },
    },
  };

  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.profile.update), fakeResponse],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
      status: 'success',
    })
    .put(routerRedux.push('/account/center'))
    .run());
});
