import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../care_plan_activities';

const fakeResponse = {
  data: {
    id: 'd5b15552-a0b9-11e8-9d1a-ab5f2e366532',
    type: 'care_plan_activities',
    attributes: {
      id: 'd5b15552-a0b9-11e8-9d1a-ab5f2e366532',
      care_plan_id: null,
      organization_id: '0db4be2c-9e7f-11e8-84cc-6b8bdc7b4d29',
      location_id: null,
      plannable_id: null,
      plannable_type: null,
      progress: 'Almost done',
      status: 'not_started',
      prohibited: null,
      daily_amount: 3,
      quantity: 4,
      description: 'Describe',
      scheduled_at: '2018-08-15T18:34:16.588Z',
      created_at: '2018-08-15T18:34:24.894Z',
      updated_at: '2018-08-15T18:34:24.894Z',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
    },
  },
};

describe('CarePlanActivity Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plan_activities.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        progress: 'Almost done',
        status: 'not_started',
        prohibited: null,
        daily_amount: 3,
        quantity: 4,
        description: 'Describe',
        scheduled_at: '2018-08-15T18:34:16.588Z',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plan_activities.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plan_activities.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plan_activities.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.care_plan_activities.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
