import update from 'immutability-helper';
import _ from 'lodash';
import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '../utils/table';
import initialState from './initialStates/resource';

const resource = 'organizations';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery } = payload;
      const response = yield call(ApiClient.organizations.search, pageNumber, sortField, sortOrder, searchQuery);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { params } = payload;
      const response = yield call(ApiClient.organizations.create, params);
      yield put({
        type: 'createResource',
        payload: response,
      });
      const { data: { id } } = response;
      yield put(routerRedux.push(`/account/settings/${resource}/${id}`));
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.organizations.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.organizations.update, id, params);
      yield put({
        type: 'updateResource',
        payload: response,
      });
      yield put({ type: 'reload' });
    },
    *delete({ payload }, { call, put }) {
      const { id } = payload;
      yield call(ApiClient.organizations.delete, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put(routerRedux.push(`/account/settings/${resource}`));
    },
    *reload(action, { put, select }) {
      const { pageNumber, sortField, sortOrder, searchQuery, filters } = yield select(state => state.organizations);
      yield put({ type: 'fetch', payload: { pageNumber, sortField, sortOrder, resource, searchQuery, filters } });
    },
  },
  reducers: {
    readResource(state, action) {
      const { links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const deserialisedPayload = deserialise(action.payload);
      return {
        ...state,
        list: deserialisedPayload.data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        resource: deserialisedData.data,
      };
    },
    currentResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        resource: deserialisedData.data,
      };
    },
    deleteResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1]] }),
      };
    },
  },
};
