import { routerRedux } from 'dva/router';

import ApiClient, { resourceDefaults as initialState } from '~/services/client';
import { deserialise } from '~/utils/jsonApi/deserialise';
import { getPageInfo } from '../utils/table';
import { notificationError } from '../utils/models';

const resource = 'email_templates';

export default {
  namespace: resource,
  state: {
    ...initialState,
    variables: [],
    preview: {},
  },
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery, extraQueries } = payload;
      const response = yield call(ApiClient.email_templates.search, {
        pageNumber,
        sortField,
        sortOrder,
        searchQuery,
        extraQueries: {
          ...extraQueries,
          contentType: 'Email Templates',
        },
      });
      yield put({
        type: 'readResource',
        payload: {
          ...response,
          extraQueries: {
            ...extraQueries,
            contentType: 'Email Templates',
          },
        },
      });
    },
    *create({ payload }, { call, put }) {
      const { params } = payload;
      try {
        const response = yield call(ApiClient.email_templates.create, params);
        yield put({
          type: 'createResource',
          payload: response,
        });
        yield put({ type: 'notifications/success' });
        yield put(routerRedux.push(`/library/email_templates/builder/${response.data.id}`));
      } catch (e) { yield put(notificationError(e)); }
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.email_templates.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      const { id, params } = payload;
      try {
        const response = yield call(ApiClient.email_templates.update, id, params);
        if (response) {
          yield put({
            type: 'updateResource',
            payload: response,
          });
          yield put({ type: 'notifications/success' });
          yield put({ type: 'reload' });
        }
      } catch (e) { yield put(notificationError(e)); }
    },
    *delete({ payload }, { call, put }) {
      const { id } = payload;
      try {
        yield call(ApiClient.email_templates.delete, id);
        yield put({
          type: 'deleteResource',
          payload: { id },
        });
        yield put({ type: 'notifications/success' });
        yield put(routerRedux.push('/library/email_templates'));
        yield put({
          type: 'fetch',
          payload: { id },
        });
      } catch (e) { yield put(notificationError(e)); }
    },
    *reload(action, { put, select }) {
      const { pageNumber, sortField, sortOrder } = yield select(state => state.email_templates);
      const { searchQuery, filters } = yield select(state => state.search);
      yield put({ type: 'fetch', payload: { pageNumber, sortField, sortOrder, resource, searchQuery, filters } });
    },
    *fetchVariables(action, { call, put }) {
      const response = yield call(ApiClient.email_templates.variables);
      yield put({
        type: 'readVariables',
        payload: {
          ...response,
        },
      });
    },
    *readPreview({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.email_templates.preview, id);
      yield put({
        type: 'currentPreview',
        payload: {
          id,
          ...response,
        },
      });
    },
  },
  reducers: {
    readResource(state, action) {
      const { links, extraQueries } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const { data } = deserialise(action.payload);
      return {
        ...state,
        list: data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
        extraQueries,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const { data } = deserialise(action.payload);
      return {
        ...state,
        resource: data,
      };
    },
    currentResource(state, action) {
      const { data } = deserialise(action.payload);
      return {
        ...state,
        resource: data,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
    readVariables(state, action) {
      const { data } = deserialise(action.payload);
      return {
        ...state,
        variables: data,
      };
    },
    currentPreview(state, action) {
      const { id, body } = action.payload;
      return {
        ...state,
        preview: {
          id,
          body,
        },
      };
    },
  },
};
