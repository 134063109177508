import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link } from 'dva/router';

import styles from './ErrorPage.module.less';

class ErrorPage extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  handleRefresh = () => {
    window.location.reload();
  }

  renderButton = () => {
    const { location } = this.props;
    if (location && location.pathname === '/error') {
      return (
        <Button
          type="primary"
          onClick={this.handleRefresh}
        >
          <Link to="/patients">Back to Home</Link>
        </Button>
      );
    }
    return (
      <div>
        <Button
          type="primary"
          onClick={this.handleRefresh}
          className={styles.button}
        >
          <Link to="/patients">Back to Home</Link>
        </Button>
        <Button
          type="primary"
          onClick={this.handleRefresh}
        >
        Refresh Page
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.errorPage}>
        <h1>Something went wrong.</h1>
        <h2>If this error persists, please contact customer support.</h2>
        {this.renderButton()}
      </div>
    );
  }
}

export default ErrorPage;
