import update from 'immutability-helper';
import _ from 'lodash';
import ApiClient from '~/services/client';
import initialState from './initialStates/resource';

const resource = 'questionnaire_items';

export default {
  namespace: resource,
  state: initialState,
  effects: {
    *create({ payload }, { call, put }) {
      const { parentId, params, tagParams = {} } = payload;
      const response = yield call(ApiClient.questionnaire_items.create, parentId, params);
      if (response) {
        if (!_.isEmpty(tagParams.tag_name)) {
          yield put.resolve({
            type: 'resource_tags/create',
            payload: {
              id: response.data.id,
              resourceType: 'questionnaire_items',
              params: {
                ...tagParams,
              },
            },
          });
        }
        yield put({
          type: 'createResource',
          payload: response,
        });
        yield put({
          type: 'questionnaires/read',
          payload: {
            id: parentId,
          },
        });
      }
    },
    *update({ payload }, { call, put }) {
      const { parentId, id, params } = payload;
      const response = yield call(ApiClient.questionnaire_items.update, parentId, id, params);
      yield put({
        type: 'updateResource',
        payload: response.data,
      });
      yield put({
        type: 'questionnaires/read',
        payload: {
          id: parentId,
        },
      });
    },
    *delete({ payload }, { call, put }) {
      const { parentId, id } = payload;
      yield call(ApiClient.questionnaire_items.delete, parentId, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put({
        type: 'questionnaires/read',
        payload: {
          id: parentId,
        },
      });
    },
  },
  reducers: {
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const { id, attributes } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
        resource: attributes,
      };
    },
    deleteResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1]] }),
      };
    },
  },
};
