import update from 'immutability-helper';
import _ from 'lodash';
import normalize from 'json-api-normalizer';
import { routerRedux } from 'dva/router';
import { deserialise } from '~/utils/jsonApi/deserialise';
import ApiClient from '~/services/client';
import { getPageInfo } from '../utils/table';
import { matchPath, redirectToIndex } from '../utils/router';
import initialState from './initialStates/resource';

const resource = 'workflows';

export default {
  namespace: resource,
  state: {
    ...initialState,
    options: [],
  },
  effects: {
    *fetch({ payload }, { call, put }) {
      const { pageNumber, sortField, sortOrder, searchQuery } = payload;
      const response = yield call(ApiClient.workflows.search, pageNumber, sortField, sortOrder, searchQuery);
      yield put({
        type: 'readResource',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      const { params } = payload;
      const response = yield call(ApiClient.workflows.create, params);
      if (response) {
        yield put({
          type: 'createResource',
          payload: response,
        });
        yield put(routerRedux.push(`/workflows/${response.data.id}`));
      }
    },
    *read({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(ApiClient.workflows.read, id);
      yield put({
        type: 'currentResource',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      const { id, params } = payload;
      const response = yield call(ApiClient.workflows.update, id, params);
      if (response) {
        yield put({
          type: 'updateResource',
          payload: response.data,
        });
        yield put({
          type: 'read',
          payload: response.data,
        });
      }
    },
    *delete({ payload }, { call, put, select }) {
      const { id } = payload;
      yield call(ApiClient.workflows.delete, id);
      yield put({
        type: 'deleteResource',
        payload: { id },
      });
      yield put({
        type: 'fetch',
        payload: { id },
      });
      yield call(redirectToIndex, { put, select }, resource);
    },
    *fetchOptions(action, { call, put }) {
      const response = yield call(ApiClient.workflows.options);
      yield put({
        type: 'fetchedOptions',
        payload: response,
      });
    },
    *clearResource(action, { put }) {
      yield put({
        type: 'clearedResource',
      });
    },
    *copyTriggeredAction({ payload }, { call, put, select }) {
      const { trigger, trigger: { actions } } = payload;
      const triggerResponse = yield call(ApiClient.triggered_actions.create, trigger);
      if (triggerResponse) {
        const { id } = triggerResponse.data;
        if (actions[0]) {
          const { name, command, params } = actions[0];
          const action = {
            triggered_action_id: id,
            name,
            command,
            params,
          };
          yield call(ApiClient.actions.create, action);
        }
        yield put({
          type: 'read',
          payload: {
            id: trigger.workflow_id,
          },
        });
      }
    },
    *createTrigger({ payload }, { call, put }) {
      const { trigger } = payload;
      const triggerResponse = yield call(ApiClient.triggered_actions.create, trigger);
      if (triggerResponse) {
        yield put({
          type: 'read',
          payload: {
            id: trigger.workflow_id,
          },
        });
      }
    },
    *updateTrigger({ payload }, { call, put }) {
      const { trigger } = payload;
      const triggerResponse = yield call(ApiClient.triggered_actions.update, trigger.id, trigger);
      if (triggerResponse) {
        yield put({
          type: 'read',
          payload: {
            id: trigger.workflow_id,
          },
        });
      }
    },
    *deleteTrigger({ payload }, { call, put, select }) {
      const { triggerId } = payload;
      const workflowId = yield select(state => state.workflows.resource.id);
      yield call(ApiClient.triggered_actions.delete, triggerId);
      yield put({
        type: 'read',
        payload: {
          id: workflowId,
        },
      });
    },
    *createAction({ payload }, { call, put, select }) {
      const { action } = payload;
      const actionResponse = yield call(ApiClient.actions.create, action);
      const workflowId = yield select(state => state.workflows.resource.id);
      if (actionResponse) {
        yield put({
          type: 'read',
          payload: {
            id: workflowId,
          },
        });
      }
    },
    *updateAction({ payload }, { call, put, select }) {
      const { action } = payload;
      const actionResponse = yield call(ApiClient.actions.update, action.id, action);
      const workflowId = yield select(state => state.workflows.resource.id);
      if (actionResponse) {
        yield put({
          type: 'read',
          payload: {
            id: workflowId,
          },
        });
      }
    },
    *deleteAction({ payload }, { call, put, select }) {
      const { actionId } = payload;
      const workflowId = yield select(state => state.workflows.resource.id);
      yield call(ApiClient.actions.delete, actionId);
      yield put({
        type: 'read',
        payload: {
          id: workflowId,
        },
      });
    },
  },
  reducers: {
    readResource(state, action) {
      const { links } = action.payload;
      const { pageNumber, pageSize, total, sortField, sortOrder, searchQuery } = getPageInfo(links);
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        list: deserialisedData.data,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
        searchQuery,
      };
    },
    createResource(state) {
      return {
        ...state,
      };
    },
    updateResource(state, action) {
      const { id } = action.payload;
      const index = _.findIndex(state.list, { id });
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1, action.payload]] }),
      };
    },
    currentResource(state, action) {
      const deserialisedData = deserialise(action.payload);
      const normalizedData = normalize(action.payload, { camelizeKeys: false });

      deserialisedData.data.triggered_actions = _.sortBy(deserialisedData.data.triggered_actions, 'created_at');
      return {
        ...state,
        resource: deserialisedData.data,
        normalized: normalizedData,
      };
    },
    deleteResource(state) {
      return {
        ...state,
      };
    },
    fetchedOptions(state, action) {
      const deserialisedData = deserialise(action.payload);
      return {
        ...state,
        options: deserialisedData.data,
      };
    },
    clearedResource(state) {
      return {
        ...state,
        resource: {},
      };
    },
  },
  subscriptions: {
    clearNew({ history, dispatch }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, '/workflows/new')) {
          dispatch({ type: 'clearResource' });
        }
      });
    },
  },
};
