import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../organizations';

const fakeResponse = {
  data: {
    id: 'ddaa7494-101a-11e8-9084-3f4e50f5cca4',
    type: 'organizations',
    attributes: {
      id: 'ddaa7494-101a-11e8-9084-3f4e50f5cca4',
      account_id: null,
      name: 'Kihn Group',
      address: '850 Pacocha Curve',
      endpoint: 'http://example.com/lupe_schulist',
      source: 'kihngroup.com',
      source_id: null,
      subdomain: 'roob',
      preferences: {},
      created_at: '2018-02-12T17:33:40.850Z',
      updated_at: '2018-06-28T22:25:41.841Z',
      created_by: null,
      updated_by: null,
      address_city: null,
      address_state: null,
      address_postalcode: null,
      address_country: null,
    },
  },
};

describe('Organizations Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.organizations.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        name: 'Kihn Group',
        address: '850 Pacocha Curve',
        endpoint: 'http://example.com/lupe_schulist',
        source: 'kihngroup.com',
        subdomain: 'roob',
      },
    },
  };
  test('create', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.organizations.create), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .run());

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.organizations.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.organizations.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.organizations.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
