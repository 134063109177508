import { routerRedux } from 'dva/router';
import ApiClient from '~/services/client';
import { notification } from 'antd';
import _ from 'lodash';
import update from 'immutability-helper';
import inflection from 'inflection';
import { deserialise } from '../utils/jsonApi/deserialise';
import { authorize, checkForResponse, logout, getCurrentUser } from '../services/auth';
import { setAuthority } from '../utils/authority';
import { setOrganizationId } from '../utils/user';
import { reloadAuthorized } from '../utils/Authorized';
import { notificationError } from '../utils/models';
import initialState from './initialStates/auth';

export default {
  namespace: 'auth',
  state: initialState,
  effects: {
    *authorize({ payload = {} }, { call, put }) {
      try {
        yield call(authorize, payload);
      } catch (error) {
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'error',
            currentAuthority: 'guest',
          },
        });
        reloadAuthorized();
      }
    },
    *checkResponse(_, { call, put }) {
      try {
        yield call(checkForResponse);
        yield put({
          type: 'profile/readResource',
          payload: {
            data: {
              attributes: getCurrentUser(),
            },
          },
        });

        const response = yield call(ApiClient.profile.read);
        const {
          data: { attributes },
        } = response;
        const organizationId = attributes.organizations[0].id || attributes.organization_id;
        setOrganizationId(organizationId);

        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: true,
            currentAuthority: attributes.roles,
          },
        });
        reloadAuthorized();
        yield put(routerRedux.push('/'));
      } catch (e) {
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'error',
            currentAuthority: 'guest',
          },
        });
        reloadAuthorized();
        yield put(routerRedux.push('/'));
      }
    },
    *logout(_, { call, put, select }) {
      try {
        // get location pathname
        const urlParams = new URL(window.location.href);
        const pathname = yield select(state => state.routing.location.pathname);
        // add the parameters in the url
        urlParams.searchParams.set('redirect', pathname);
        window.history.replaceState(null, 'login', urlParams.href);
      } finally {
        yield call(logout);
        yield put({
          type: 'RESET',
        });
        setOrganizationId(null);
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: false,
            currentAuthority: 'guest',
          },
        });
        reloadAuthorized();
        yield put(routerRedux.push('/user/login'));
      }
    },
    *changePassword({ payload }, { call }) {
      yield call(ApiClient.auth.changePassword, payload);
    },
    *getInvitationData({ payload }, { call, put }) {
      try {
        const response = yield call(ApiClient.auth.readInvitation, payload);
        yield put({
          type: 'updateInvitationData',
          payload: response.data,
        });
      } catch (error) {
        // console.log(error);
      }
    },
    *acceptInvitation({ payload }, { call, put }) {
      try {
        yield call(ApiClient.auth.acceptInvitation, payload.invitationToken, payload.params);
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: true,
            currentAuthority: 'admin',
          },
        });
        reloadAuthorized();
        yield put(routerRedux.push('/user/login'));
      } catch (error) {
        // console.log(error);
      }
    },
    *sendInvite({ payload }, { call, put, select }) {
      try {
        const inviteResponse = yield call(ApiClient.auth.sendInvite, payload);
        const { resource_id, resource_type } = payload;
        const resourceType = inflection.pluralize(_.lowerCase(resource_type));
        const resourceState = yield select(state => state[resourceType]);

        const { list: resourceList } = resourceState;
        const index = _.findIndex(resourceList, { id: resource_id });
        const { data } = deserialise(inviteResponse);

        const updatedData = {
          ...resourceList[index],
          invitation_sent_at: data.invitation_sent_at,
        };
        const updatedList = update(resourceList, { $splice: [[index, 1, updatedData]] });

        const {
          pageNumber,
          pageSize,
          total,
          sortField,
          sortOrder,
          searchQuery,
          extraQueries,
        } = resourceState;

        const readPayload = {
          extraQueries,
          data: updatedList,
          links: {
            pageNumber,
            pageSize,
            total,
            sortField,
            sortOrder,
            searchQuery,
          },
        };

        yield put({
          type: `${resourceType}/readResource`,
          payload: readPayload,
        });

        notification.success({
          message: 'Invitation successfully sent.',
        });
      } catch (error) {
        yield put(notificationError(error));
      }
    },
    *switchOrganization({ payload }, { put, call }) {
      yield call(ApiClient.profile.selectOrganization, payload.key);
      yield put({
        type: 'SWITCH_ORG',
      });
      yield put({
        type: 'profile/fetch',
      });
      yield put(routerRedux.push('/'));
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);
      return {
        ...state,
        status: payload.status,
        type: payload.type,
      };
    },
    updateInvitationData(state, { payload }) {
      return {
        ...state,
        invitationData: payload.attributes,
      };
    },
  },
};
