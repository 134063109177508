import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import Model from '../template_activities';

const fakeResponse = {
  data: {
    id: 'abcdef',
    type: 'tags',
    attributes: {
      id: '123',
      care_plan_activities: [],
      category: 'template',
      contents: [],
      created_at: '2019-01-03T15:50:32.138Z',
      created_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      creator: { first_name: 'bob', last_name: 'User' },
      description: '1',
      end_date: null,
      episode_of_care_id: null,
      intent: '1',
      location_id: null,
      organization_id: 'af428da4-0ab9-11e9-80bd-270d4f704345',
      patient_id: null,
      practitioner_id: null,
      preferences: {},
      start_date: null,
      status: 'active',
      title: '1',
      type: 'care_plans',
      updated_at: '2019-04-03T21:37:34.033Z',
      updated_by: 'af4bd422-0ab9-11e9-80bd-1b9a0c315038',
      updater: { first_name: 'bob', last_name: 'User' },
    },
  },
};

describe('Resource Tag Model', () => {
  const fakeResourceTagAction = {
    payload: {
      id: 'abcdef',
      parentId: '123',
    },
  };

  const { payload: { parentId } } = fakeResourceTagAction;

  test('fetch', () => expectSaga(Model.effects.fetch, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.template_activities.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  test('create', () => expectSaga(Model.effects.create, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.template_activities.create), fakeResponse],
    ])
    .put({
      type: 'createResource',
      payload: fakeResponse,
    })
    .put({
      type: 'templates/read',
      payload: {
        id: parentId,
      },
    })
    .run());

  test('read', () => expectSaga(Model.effects.read, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.template_activities.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse.data,
    })
    .run());

  test('update with response', () => expectSaga(Model.effects.update, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.template_activities.update), fakeResponse],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .put({
      type: 'templates/read',
      payload: {
        id: parentId,
      },
    })
    .run());

  test('update with no response', () => expectSaga(Model.effects.update, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.template_activities.update), null],
    ])
    .not.put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .not.put({
      type: 'templates/read',
      payload: {
        id: parentId,
      },
    })
    .run());

  test('delete', () => expectSaga(Model.effects.delete, fakeResourceTagAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.template_activities.delete), ''],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeResourceTagAction.payload.id },
    })
    .put({
      type: 'templates/read',
      payload: {
        id: parentId,
      },
    })
    .run());
});
