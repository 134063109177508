import AuthClient from '@keethealth/auth-client';

const client = new AuthClient({
  url: process.env.REACT_APP_OAUTH2_ISSUER_URL,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
});

export async function getCurrentSession() {
  return client.getCurrentSession();
}

export function getCurrentUser() {
  return client.getCurrentUser();
}

export async function logout() {
  return client.signOut();
}

export function authorize(opts = {}) {
  return client.authorize({
    redirectUri: process.env.REACT_APP_LOGIN_URL,
    scope: (process.env.REACT_APP_OAUTH2_SCOPES || '').replace(/,/g, ' '),
    audience: [process.env.REACT_APP_OAUTH2_AUDIENCE],
    extras: {
      ...opts,
    },
  });
}

export async function checkForResponse() {
  return client.checkForResponse();
}
