import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AirbrakeClient from 'airbrake-js';

import ErrorPage from '~/components/ErrorPage';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = new AirbrakeClient({
      projectId: process.env.REACT_APP_AIRBRAKE_ID,
      projectKey: process.env.REACT_APP_AIRBRAKE_KEY,
      environment: process.env.REACT_APP_DEPLOY_ENV,
    });
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    this.airbrake.notify({
      error,
      params: { info },
    });
  }

  handleRefresh = () => {
    window.location.reload();
  }

  render() {
    const { hasError } = this.state;
    const { children, ...otherProps } = this.props;
    if (hasError) {
      return (
        <ErrorPage {...otherProps} />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
