export function getOrganizationId() {
  return localStorage.getItem('organization-id') || '';
}

export function setOrganizationId(id) {
  if (id) {
    return localStorage.setItem('organization-id', id);
  }
  return localStorage.removeItem('organization-id');
}

