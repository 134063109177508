import React, { Component } from 'react';
import { Tag, Tooltip, Spin } from 'antd';
import PropTypes from 'prop-types';

class TagsShow extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  renderTag = (tag) => {
    const isLongTag = tag.length > 20;
    const tagElem = (
      <Tag key={tag}>
        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
      </Tag>
    );
    return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
  }

  render() {
    const { value } = this.props;
    return value
      ? (
        <div>
          {value.map(this.renderTag)}
        </div>
      ) : (
        <div className="spinWrapper">
          <Spin size="small" />
        </div>
      );
  }
}

export default TagsShow;
