import { call, put } from 'redux-saga/effects';
import { expectSaga } from 'redux-saga-test-plan';
import * as matchers from 'redux-saga-test-plan/matchers';
import ApiClient from '~/services/client';
import { redirectToIndex } from '~/utils/router';
import Model from '../contents';

const fakeResponse = {
  data: {
    id: '999380d0-9101-11e8-81f8-1bf85ab5a34b',
    type: 'contents',
    attributes: {
      id: '999380d0-9101-11e8-81f8-1bf85ab5a34b',
      organization_id: '3340615c-8168-11e8-bc0f-2b6b8e2bd540',
      active: true,
      identifier: null,
      language: null,
      name: 'youtube ego altr',
      title: 'youtube ego altr',
      author: 'kurzgesagt',
      status: 'published',
      properties: {
        content: 'content',
        body_part: 'bp',
        diagnosis: 'diag',
        is_helpful: true,
        is_template: true,
        save_to_clinic: true,
      },
      created_at: '2018-07-26T18:27:49.236Z',
      updated_at: '2018-07-31T05:48:32.400Z',
      created_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      updated_by: '1104ecee-101a-11e8-9214-bfbfdcb91301',
      category_id: null,
      content_type: 'Education',
      body: null,
      sub_title: null,
      custodian: null,
      security_label: null,
      description: null,
      note: null,
      tag_list: null,
      body_part_list: null,
      body_position_list: null,
      custom_list: null,
      equipment_list: null,
      exercise_type_list: null,
    },
    relationships: {
      media: {
        data: [
          {
            id: '21b97f06-9101-11e8-81f8-dfad92f4689a',
            type: 'media',
          },
        ],
      },
    },
  },
  extraQueries: undefined,
};

describe('Contents Model', () => {
  const fakeSearchAction = {
    payload: {
      pageNumber: 1,
      sortField: '',
      sortOrder: '',
      searchQuery: '',
    },
  };
  test('fetch', () => expectSaga(Model.effects.fetch, fakeSearchAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.contents.search), fakeResponse],
    ])
    .put({
      type: 'readResource',
      payload: fakeResponse,
    })
    .run());

  const fakeCreateAction = {
    payload: {
      params: {
        content_type: 'Exercise',
        name: 'Full Body Stretch',
        author: 'Brendon',
        active: true,
        status: 'published',
        properties: {
          number_of_reps: '25',
        },
      },
    },
  };

  describe('create', () => {
    describe('when params include tags', () => {
      test('it creates tags after resource creation', () => expectSaga(Model.effects.create, { payload: {
        params: fakeCreateAction.payload.params,
        tagParams: {
          tag_name: ['tag'],
        },
      } }, { call, put })
        .provide([
          [matchers.call.fn(ApiClient.contents.create), fakeResponse],
          [matchers.call.fn(redirectToIndex)],
        ])
        .put.resolve({
          type: 'resource_tags/create',
          payload: {
            id: fakeResponse.data.id,
            resourceType: 'contents',
            params: {
              tag_name: ['tag'],
            },
          },
        })
        .put({
          type: 'createResource',
          payload: fakeResponse,
        })
        .run());
    });

    describe('when params do not include tags', () => {
      test('it creates resource', () => expectSaga(Model.effects.create, fakeCreateAction, { call, put })
        .provide([
          [matchers.call.fn(ApiClient.contents.create), fakeResponse],
          [matchers.call.fn(redirectToIndex)],
        ])
        .put({
          type: 'createResource',
          payload: fakeResponse,
        })
        .run());
    });
  });

  const fakeReadAction = {
    payload: {
      id: 'abc',
    },
  };
  test('read', () => expectSaga(Model.effects.read, fakeReadAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.contents.read), fakeResponse],
    ])
    .put({
      type: 'currentResource',
      payload: fakeResponse,
    })
    .run());

  const fakeUpdateAction = {
    payload: {
      id: 'abc',
      params: {
        content_type: 'Education',
      },
    },
  };
  test('update', () => expectSaga(Model.effects.update, fakeUpdateAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.contents.update), fakeResponse],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'updateResource',
      payload: fakeResponse.data,
    })
    .run());

  const fakeDeleteAction = {
    payload: {
      id: 'abc',
    },
  };
  test('delete', () => expectSaga(Model.effects.delete, fakeDeleteAction, { call, put })
    .provide([
      [matchers.call.fn(ApiClient.contents.delete), ''],
      [matchers.call.fn(redirectToIndex)],
    ])
    .put({
      type: 'deleteResource',
      payload: { id: fakeDeleteAction.payload.id },
    })
    .run());
});
